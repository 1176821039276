import React from 'react';

const NotFound = () => {
    return (
        <>
            <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
                <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex-shrink-0 flex justify-center">
                        <a href="/" className="inline-flex">
                            <span className="sr-only">Workflow</span>
                            <img
                                className="h-12 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=highlight&shade=600"
                                alt=""
                            />
                        </a>
                    </div>
                    <div className="py-16">
                        <div className="text-center">
                            <p className="text-sm font-semibold text-highlight-500 uppercase tracking-wide">
                                404 error
                            </p>
                            <h1 className="mt-2 text-4xl font-extrabold text-neutral-900 tracking-tight sm:text-5xl">
                                Page not found.
                            </h1>
                            <p className="mt-2 text-base text-neutral-500">
                                Sorry, we couldn’t find the page you’re looking
                                for.
                            </p>
                            <div className="mt-6">
                                <a
                                    href="/"
                                    className="text-base font-medium text-highlight-500 hover:text-highlight-500"
                                >
                                    Go back home
                                    <span aria-hidden="true"> &rarr;</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </main>
                <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                    <nav className="flex justify-center space-x-4">
                        <a
                            href="mailto:support@we-shape.co.uk"
                            className="text-sm font-medium text-neutral-500 hover:text-neutral-600"
                        >
                            Contact Support
                        </a>
                    </nav>
                </footer>
            </div>
        </>
    );
};

export default NotFound;
