import React from 'react';

export const SpaceWrapper = ({ children, title }) => {
    return (
        <div className="pt-24 sm:pt-28 lg:pt-16 pb-16 px-4">
            <main>
                <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
                    {title && (
                        <div className="prose text-center mx-auto">
                            <h1>{title}</h1>
                        </div>
                    )}
                    {children}
                </div>
            </main>
        </div>
    );
};
