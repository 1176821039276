import React from 'react';
import { SpaceWrapper } from '../../components/SpaceWrapper';

export const BlankSpace = () => {
    return (
        <SpaceWrapper>
            <button
                type="button"
                className="relative block w-full border-2 border-neutral-300 border-dashed rounded-lg p-24 text-center hover:border-neutral-400 focus:outline-none"
            >
                <span className="mt-2 block text-3xl font-bold text-neutral-900">
                    Coming Soon
                </span>
            </button>
        </SpaceWrapper>
    );
};
