import React from 'react';

const Section = ({ children, sectionStyle, ...props }) => {
    return (
        <div className={`bg-white shadow rounded-lg ${sectionStyle}`}>
            <div className="p-5 sm:p-6">{children}</div>
        </div>
    );
};

export default Section;
