import React, { useContext } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { doc, query } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { ErrorPopup } from '../../misc/Error';
import { Loading } from '../../misc/Loading';
import { ShowBookingDetails } from './ShowDetails';
import AuthContext from '../../../context/AuthContext';

const BookingDetails = ({ id }) => {
    const { admin } = useContext(AuthContext);
    const q = query(doc(db, 'bookings', id));
    const [snapshot, loading, error] = useDocumentData(q);

    return (
        <>
            {error && <ErrorPopup error={error.message} />}
            {loading || error || !snapshot ? (
                <Loading />
            ) : (
                <ShowBookingDetails booking={snapshot} admin={admin} id={id} />
            )}
        </>
    );
};

export default BookingDetails;
