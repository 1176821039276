import React, { useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
    addNewUser,
    deleteUser,
    updateUser,
} from '../../../services/firebase-user-service';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';

import Section from '../../../components/Section';
import { ErrorPopup } from '../../misc/Error';
// FIELDS
import {
    GridFieldWrapper,
    ESSTextField,
    ESSCheckboxField,
    ESSPasswordField,
    ESSTextareaField,
} from '../../../components/Fields';
import { scrollToPosition } from '../../../App';

export const AddUser = ({ initial, id }) => {
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const edit = searchParams.get('edit') === 'true' ? true : false;

    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState('');
    const [deleteDialog, setDeleteDialog] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: initial,
    });

    const submitEvent = async (data) => {
        setFormLoading(true);
        if (edit) {
            try {
                await updateUser(id, data);
                setTimeout(() => {
                    setFormLoading(false);
                    navigate(
                        `/users?status=updatedUser&user=${data.first_name}`,
                        {
                            replace: true,
                        }
                    );
                }, 1000);
            } catch (error) {
                scrollToPosition();
                setFormError(error);
                setFormLoading(false);
                console.error(error);
            }
        } else {
            try {
                await addNewUser(data);
                setTimeout(() => {
                    setFormLoading(false);
                    navigate(
                        `/users?status=createdUser&user=${data.first_name}`,
                        { replace: true }
                    );
                }, 1000);
            } catch (error) {
                setFormError(error);
                setFormLoading(false);
                console.error(error.message);
            }
        }
    };

    const handleDeleteUser = async () => {
        await deleteUser(id);
        navigate('/users?status=deletedUser', { replace: true });
    };

    let buttonText = edit ? 'Amend User' : 'Create User';

    return (
        <div className="mt-6 max-w-3xl mx-auto">
            {formError && <ErrorPopup error={formError.message} />}
            <form className="space-y-8" onSubmit={handleSubmit(submitEvent)}>
                <Section>
                    <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                        <div>
                            <h3 className="text-lg leading-6 font-bold text-neutral-900">
                                User Information
                            </h3>
                        </div>
                        <div className="divide-y divide-neutral-200">
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="First Name"
                                    id="first_name"
                                    type="text"
                                    register={register}
                                    options={{
                                        required:
                                            "Please enter the user's first name.",
                                    }}
                                    error={errors.first_name}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Last Name"
                                    id="last_name"
                                    type="text"
                                    register={register}
                                    options={{
                                        required:
                                            "Please enter the user's last name.",
                                    }}
                                    error={errors.last_name}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Email Address"
                                    id="email"
                                    type="text"
                                    register={register}
                                    options={{
                                        required:
                                            "Please enter the user's email.",
                                    }}
                                    error={errors.email}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Contact Telephone"
                                    id="telephone"
                                    type="text"
                                    register={register}
                                />
                            </GridFieldWrapper>
                        </div>
                    </div>
                </Section>
                <Section>
                    <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                        <div>
                            <h3 className="text-lg leading-6 font-bold text-neutral-900">
                                Company Details
                            </h3>
                        </div>
                        <div className="divide-y divide-neutral-200">
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Company Name"
                                    id="company_name"
                                    type="text"
                                    register={register}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextareaField
                                    rows={3}
                                    label="Company Address"
                                    id="company_address"
                                    register={register}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Company Telephone"
                                    id="company_telephone"
                                    type="text"
                                    register={register}
                                />
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <ESSTextField
                                    label="Company Email"
                                    id="company_email"
                                    type="text"
                                    register={register}
                                />
                            </GridFieldWrapper>
                        </div>
                    </div>
                </Section>
                <Section>
                    <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                        <div>
                            <h3 className="text-lg leading-6 font-bold text-neutral-900">
                                App Settings
                            </h3>
                        </div>
                        <div className="divide-y divide-neutral-200">
                            <GridFieldWrapper>
                                <div className="block text-sm font-bold text-neutral-700 sm:mt-px">
                                    Admin User
                                </div>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <ESSCheckboxField
                                        id="isAdmin"
                                        label="Is this user an admin?"
                                        register={register}
                                    />
                                </div>
                            </GridFieldWrapper>
                            <GridFieldWrapper>
                                <div className="block text-sm font-bold text-neutral-700 sm:mt-px">
                                    Active User
                                </div>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <ESSCheckboxField
                                        id="active"
                                        label="Is this user active?"
                                        register={register}
                                    />
                                </div>
                            </GridFieldWrapper>
                        </div>
                    </div>
                </Section>
                {!edit && (
                    <Section>
                        <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                            <div>
                                <h3 className="text-lg leading-6 font-bold text-neutral-900">
                                    Password & Security
                                </h3>
                            </div>
                            <div className="divide-y divide-neutral-200">
                                <GridFieldWrapper>
                                    <ESSPasswordField
                                        label="Password"
                                        id="password"
                                        type="text"
                                        register={register}
                                        options={{
                                            required:
                                                'Please enter a password for the new user.',
                                        }}
                                        error={errors.password}
                                    />
                                </GridFieldWrapper>
                            </div>
                        </div>
                    </Section>
                )}
                <Section>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={formLoading}
                            className={`w-full justify-center flex items-center h-12 border border-transparent shadow-sm font-medium rounded-md text-white bg-highlight-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-highlight-500 ${
                                formLoading
                                    ? 'hover:bg-highlight-500'
                                    : 'hover:bg-highlight-700'
                            }`}
                        >
                            {formLoading ? (
                                <span className="animate-spin rounded-full border-2 border-t-2 border-t-highlight-700 border-white w-6 h-6 inline-block"></span>
                            ) : (
                                buttonText
                            )}
                        </button>
                    </div>
                </Section>
            </form>

            <div className="mt-12 text-center">
                <button
                    className="text-red-500"
                    onClick={() => setDeleteDialog(true)}
                >
                    Delete User
                </button>
            </div>

            <Transition.Root show={deleteDialog} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={() => setDeleteDialog(false)}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-neutral-900"
                                        >
                                            Delete User
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-neutral-500">
                                                Are you sure you want to cancel
                                                this user? This action cannot be
                                                undone.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => handleDeleteUser()}
                                    >
                                        Delete User
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-neutral-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-neutral-700 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => setDeleteDialog(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
