import { signOut } from 'firebase/auth';
import { auth } from '../config/firebase';

// AUTHENTICATION
const checkAuthToken = () => {
    let authToken = sessionStorage.getItem('Auth Token');
    return authToken ? authToken : false;
};

const getAuthHeader = async () => {
    if (auth.currentUser) {
        return { Authorization: `${await auth.currentUser.getIdToken()}` };
    }
};

const logout = () => {
    signOut(auth);
    sessionStorage.removeItem('Auth Token');
};

// EXPORTS
export { checkAuthToken, getAuthHeader, logout };
