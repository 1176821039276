import React from 'react';
import { PlusSmIcon } from '@heroicons/react/solid';
import { Link } from 'react-router-dom';

export const NewJobButton = ({ title }) => {
    return (
        <Link
            to={`/bookings/create`}
            type="button"
            className="relative inline-flex items-center px-4 py-3 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-brand shadow-sm hover:bg-highlight-200"
        >
            <PlusSmIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            <span>{title ? title : 'New Job'}</span>
        </Link>
    );
};

export const PrimaryButton = ({ to, title, icon }) => {
    return (
        <Link
            to={to}
            type="button"
            className="relative inline-flex items-center px-4 py-3 border border-transparent text-sm font-medium rounded-md text-gray-900 bg-brand shadow-sm hover:bg-highlight-200"
        >
            {title}
            {icon && <icon className="mr-3" />}
        </Link>
    );
};
