import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { useSearchParams } from 'react-router-dom';
import { LoadingSection } from '../misc/Loading';
import { ErrorPopup } from '../misc/Error';
import { SpaceWrapper } from '../../components/SpaceWrapper';

import { UserDetails } from '../users/details';

const Profile = () => {
    const { user, loading, error } = useContext(AuthContext);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();

    if (loading) {
        return <LoadingSection />;
    }

    if (error) {
        return <ErrorPopup error={error} />;
    }

    return (
        <SpaceWrapper>
            <div className="max-w-4xl mx-auto relative">
                <UserDetails id={user.uid} />
            </div>
        </SpaceWrapper>
    );
};

export default Profile;
