import { useContext, useLayoutEffect } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
    useLocation,
} from 'react-router-dom';

import AuthContext, { AuthContextProvider } from './context/AuthContext';
import AppContext, { AppContextProvider } from './context/AppContext';
import { Login } from './views/auth/Login';
import { ResetPassword } from './views/auth/ResetPassword';
import { Loading } from './views/misc/Loading';
import { MainApplication } from './views/application';
import NotFound from './views/misc/NotFound';
import { ErrorScreen } from './views/misc/Error';

const RequireAuth = ({ children }) => {
    const { loading, authenticated } = useContext(AuthContext);
    const { appError } = useContext(AppContext);

    let location = useLocation();

    if (loading) {
        return <Loading />;
    }

    // if (appError) {
    //     return <ErrorScreen error={appError} />;
    // }

    if (!authenticated) {
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    return children;
};

export const scrollToPosition = (top = 0) => {
    try {
        window.scroll({
            top: top,
            left: 0,
            behavior: 'smooth',
        });
    } catch (_) {
        window.scrollTo(0, top);
    }
};
const Wrapper = ({ children }) => {
    const location = useLocation();
    useLayoutEffect(() => {
        scrollToPosition();
    }, [location.pathname]);
    return children;
};

function App() {
    return (
        <AppContextProvider>
            <AuthContextProvider>
                <Router>
                    <Wrapper>
                        <Routes>
                            <Route
                                exact
                                path="/*"
                                element={
                                    <RequireAuth>
                                        <MainApplication />
                                    </RequireAuth>
                                }
                            />
                            <Route path="/login" element={<Login />} />
                            <Route
                                path="/reset-password"
                                element={<ResetPassword />}
                            />
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Wrapper>
                </Router>
            </AuthContextProvider>
        </AppContextProvider>
    );
}

export default App;
