import React, { useState } from 'react';
import { Tab } from '@headlessui/react';
import Section from '../../../components/Section';
import { NewJobButton } from '../../../components/Buttons';
import { BookingListWrapper } from './BookingList';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const BookingList = () => {
    const [selectedIndex, setSelectedIndex] = useState(0);
    let [statuses] = useState(['Awaiting Approval', 'Approved', 'Inactive']);

    return (
        <>
            <div className="w-full">
                <Tab.Group
                    selectedIndex={selectedIndex}
                    onChange={setSelectedIndex}
                >
                    <Tab.List className="flex bg-white mt-6 sm:mt-12 mb-6 shadow rounded-lg">
                        {statuses.map((status, index) => (
                            <Tab
                                key={status + index}
                                className={({ selected }) =>
                                    classNames(
                                        selected
                                            ? 'text-gray-900 bg-brand'
                                            : 'text-neutral-500 hover:text-neutral-700 bg-white',
                                        index === 0 ? 'rounded-l-lg' : '',
                                        index === statuses.length - 1
                                            ? 'rounded-r-lg'
                                            : '',
                                        'group relative min-w-0 flex-1 overflow-hidden py-4 px-4 text-sm font-medium text-center hover:md:bg-neutral-50 focus:z-10'
                                    )
                                }
                            >
                                <span>{status}</span>
                            </Tab>
                        ))}
                    </Tab.List>
                    <Tab.Panels>
                        {statuses.map((status, idx) => (
                            <Tab.Panel key={idx}>
                                <Section>
                                    <BookingListWrapper status={status} />
                                </Section>
                            </Tab.Panel>
                        ))}
                    </Tab.Panels>
                </Tab.Group>
            </div>
            <div className="mt-8 flex justify-center">
                <NewJobButton title={`Request new booking`} />
            </div>
        </>
    );
};
