import React from 'react';
import Section from '../../../components/Section';
import { useForm } from 'react-hook-form';

// FIELDS
import { GridFieldWrapper, ESSTextareaField } from '../../../components/Fields';
import { updateBookingNotes } from '../../../services/firebase-booking-service';

const ClientBookingNotes = ({ notes }) => {
    return (
        <>
            <div className="space-y-6 sm:space-y-5">
                <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
                    <label className="block text-sm font-medium text-gray-900 sm:pt-2">
                        Booking Notes
                    </label>
                    <div className="mt-1 sm:mt-0 sm:col-span-2">
                        <div className="block w-full text-sm sm:pt-2">
                            {notes || (
                                <p className="text-neutral-300">
                                    No additional notes added.
                                </p>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

const AdminBookingNotes = ({ notes, id }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm({
        defaultValues: {
            booking_notes: notes,
        },
    });

    const updateNotes = async (data) => {
        try {
            await updateBookingNotes(data, id);
            reset({ keepValues: true });
        } catch (error) {
            console.log('Error updating notes: ' + error.message);
        }
    };

    return (
        <>
            <form onSubmit={handleSubmit(updateNotes)}>
                <GridFieldWrapper>
                    <ESSTextareaField
                        label="Booking Notes"
                        id="booking_notes"
                        type="text"
                        register={register}
                    />
                </GridFieldWrapper>
                <div className="space-y-6 sm:space-y-5">
                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center">
                        <label className="block text-sm font-medium text-gray-900 sm:pt-2">
                            {/* Booking Notes */}
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                            <div className="block w-full text-sm sm:pt-2">
                                <button
                                    type="submit"
                                    value="Update"
                                    disabled={!isDirty}
                                    className={`p-2 px-3 bg-green-500 text-white rounded hover:bg-green-600 disabled:opacity-20 disabled:hover:bg-green-500`}
                                >
                                    Update Notes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export const BookingNotes = ({ admin, notes, id }) => {
    return (
        <Section sectionStyle={`mt-12 sm:mt-6`}>
            {admin ? (
                <AdminBookingNotes notes={notes} id={id} />
            ) : (
                <ClientBookingNotes notes={notes} />
            )}
        </Section>
    );
};
