import React, { createContext, useState, useEffect } from 'react';
import { db } from '../config/firebase';
import {
    collection,
    onSnapshot,
    orderBy,
    query,
    where,
} from 'firebase/firestore';

import _ from 'lodash';

const BookingContext = createContext();

export const BookingContextProvider = ({ user, admin, children }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [bookings, setBookings] = useState([]);
    const [bookingStats, setBookingStats] = useState([
        { name: 'Total Active Bookings', stat: '-' },
        { name: 'Total Completed Bookings', stat: '-' },
        { name: 'Cancellation Rate', stat: '-%' },
    ]);

    useEffect(() => {
        if (!user) return;

        if (admin) {
            const q = query(
                collection(db, 'bookings'),
                orderBy('event_date', 'asc')
            );

            const bookingStream = onSnapshot(
                q,
                (snapshot) => {
                    setBookings(
                        snapshot.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        }))
                    );
                    setLoading(false);
                },
                (err) => {
                    console.error(err);
                    setError(err);
                    setLoading(false);
                }
            );

            return () => {
                bookingStream();
            };
        } else {
            const q = query(
                collection(db, 'bookings'),
                where('uid', '==', user.uid),
                orderBy('event_date', 'asc')
            );

            const bookingStream = onSnapshot(
                q,
                (snapshot) => {
                    setBookings(
                        snapshot.docs.map((doc) => ({
                            ...doc.data(),
                            id: doc.id,
                        }))
                    );
                    setLoading(false);
                },
                (err) => {
                    console.error(err);
                    setError(err);
                    setLoading(false);
                }
            );

            return () => {
                bookingStream();
            };
        }
    }, [user, admin]);

    useEffect(() => {
        let totalBookings = _.filter(bookings, { active: true }).length;
        let completedBookings = _.filter(bookings, {
            active: false,
            status: 'Completed',
        }).length;
        let cancelledBookingsRate =
            Math.round(
                (_.filter(bookings, { active: false, status: 'Cancelled' })
                    .length /
                    bookings.length) *
                    100
            ) + '%';

        if (admin) {
            setBookingStats([
                { name: 'Active Bookings', stat: totalBookings },
                { name: 'Completed Bookings', stat: completedBookings },
                { name: 'Cancellation Rate', stat: cancelledBookingsRate },
            ]);
        } else {
            setBookingStats([
                { name: 'Active Bookings', stat: totalBookings },
                { name: 'Completed Bookings', stat: completedBookings },
            ]);
        }
    }, [bookings, admin]);

    const value = {
        bookingError: error,
        bookings: bookings,
        bookingCount: _.filter(bookings, { active: true }).length,
        bookingStats: bookingStats,
        loadingBookings: loading,
    };

    return (
        <BookingContext.Provider value={value}>
            {children}
        </BookingContext.Provider>
    );
};

export default BookingContext;
