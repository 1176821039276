import { useState } from 'react';
import { Link } from 'react-router-dom';
import { statusColours } from '../../components/BookingStatus';
import Section from '../../components/Section';

import { BookingList } from '../bookings/list/BookingList';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const MonthView = ({ data, month }) => {
    const days = data;
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);

    const selectDay = (index) => {
        days.forEach((element) => {
            element.isSelected = false;
        });
        days[index].isSelected = true;
        setSelectedDay(index);
        setSelectedDate(days[index].date.split('-').pop().replace(/^0/, ''));
    };

    return (
        <div className="lg:flex lg:h-full lg:flex-col">
            <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
                <div className="grid grid-cols-7 gap-px border-b border-neutral-300 bg-neutral-200 text-center text-xs font-semibold leading-6 text-neutral-700 lg:flex-none">
                    <div className="bg-white py-2">
                        M<span className="sr-only sm:not-sr-only">on</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">ue</span>
                    </div>
                    <div className="bg-white py-2">
                        W<span className="sr-only sm:not-sr-only">ed</span>
                    </div>
                    <div className="bg-white py-2">
                        T<span className="sr-only sm:not-sr-only">hu</span>
                    </div>
                    <div className="bg-white py-2">
                        F<span className="sr-only sm:not-sr-only">ri</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">at</span>
                    </div>
                    <div className="bg-white py-2">
                        S<span className="sr-only sm:not-sr-only">un</span>
                    </div>
                </div>
                <div className="flex bg-neutral-200 text-xs leading-6 text-neutral-700 lg:flex-auto">
                    <div className="hidden w-full lg:grid lg:grid-cols-7 lg:grid-rows-5 lg:gap-px">
                        {days.map((day, index) => (
                            <div
                                key={day.date}
                                onClick={() => selectDay(index)}
                                className={classNames(
                                    day.isCurrentMonth || day.isSelected
                                        ? 'bg-white'
                                        : 'bg-neutral-50 text-neutral-500',
                                    'relative py-2 px-3 min-h-[100px] cursor-pointer'
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={
                                        day.isCurrentDay
                                            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-highlight-500 font-semibold text-white'
                                            : day.isSelected
                                            ? 'flex h-6 w-6 items-center justify-center rounded-full bg-brand font-semibold text-highlight-400'
                                            : 'flex h-6 w-6 items-center justify-center'
                                    }
                                >
                                    {day.date
                                        .split('-')
                                        .pop()
                                        .replace(/^0/, '')}
                                </time>
                                {day.events.length > 0 && (
                                    <ol className="mt-2">
                                        {day.events.slice(0, 2).map((event) => (
                                            <li key={event.id}>
                                                <Link
                                                    to={`/bookings/${event.id}`}
                                                    className="group flex"
                                                >
                                                    <p className="flex-auto truncate font-medium">
                                                        <span
                                                            className={`py-1 px-2 rounded-full hover:bg-highlight-500 hover:text-white ${
                                                                statusColours[
                                                                    event.status
                                                                ].text
                                                            } ${
                                                                statusColours[
                                                                    event.status
                                                                ].bg
                                                            }`}
                                                        >
                                                            {event.event_title}
                                                        </span>
                                                    </p>
                                                </Link>
                                            </li>
                                        ))}
                                        {day.events.length > 2 && (
                                            <li className="text-neutral-500">
                                                + {day.events.length - 2} more
                                            </li>
                                        )}
                                    </ol>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="isolate grid w-full grid-cols-7 grid-rows-6 gap-px lg:hidden">
                        {days.map((day, index) => (
                            <button
                                key={day.date}
                                onClick={() => selectDay(index)}
                                type="button"
                                className={classNames(
                                    day.isCurrentMonth
                                        ? 'bg-white'
                                        : 'bg-neutral-100',
                                    (day.isSelected || day.isCurrentDay) &&
                                        'font-semibold',
                                    day.isSelected && 'text-white',
                                    !day.isSelected &&
                                        day.isCurrentDay &&
                                        'text-highlight-500',
                                    !day.isSelected &&
                                        day.isCurrentMonth &&
                                        !day.isCurrentDay &&
                                        'text-neutral-900',
                                    !day.isSelected &&
                                        !day.isCurrentMonth &&
                                        !day.isCurrentDay &&
                                        'text-neutral-500',
                                    'flex h-14 flex-col py-2 px-3 hover:bg-neutral-100 focus:z-10'
                                )}
                            >
                                <time
                                    dateTime={day.date}
                                    className={classNames(
                                        (day.isSelected || day.isCurrentDay) &&
                                            'flex h-6 w-6 items-center justify-center rounded-full',
                                        day.isCurrentDay &&
                                            'bg-highlight-500 text-white',
                                        day.isSelected &&
                                            !day.isCurrentDay &&
                                            'bg-neutral-900',
                                        'ml-auto'
                                    )}
                                >
                                    {day.date
                                        .split('-')
                                        .pop()
                                        .replace(/^0/, '')}
                                </time>
                                <p className="sr-only">
                                    {day.events.length} events
                                </p>
                                {day.events.length > 0 && (
                                    <div className="-mx-0.5 mt-auto flex flex-wrap-reverse">
                                        {day.events.map((event) => (
                                            <div
                                                key={event.id}
                                                className="mx-0.5 mb-1 h-1.5 w-1.5 rounded-full bg-neutral-400"
                                            />
                                        ))}
                                    </div>
                                )}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
            {selectedDay && days[selectedDay]?.events.length > 0 ? (
                <>
                    <Section sectionStyle={`mt-6`}>
                        <div className="p-5 mb-3 sm:p-6 font-bold border-b border-neutral-300 text-center">
                            Bookings on {selectedDate} {month}
                        </div>
                        <BookingList bookings={days[selectedDay]?.events} />
                    </Section>
                </>
            ) : null}
        </div>
    );
};

export default MonthView;
