import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { BookingStatus } from '../../../components/BookingStatus';
import { NoBookings } from './NoBookings';
import {
    TagIcon,
    UserGroupIcon,
    CalendarIcon,
    LocationMarkerIcon,
} from '@heroicons/react/outline';
import _ from 'lodash';
import { LoadingSection } from '../../misc/Loading';
import BookingContext from '../../../context/BookingContext';

export const BookingListWrapper = ({ status, limit }) => {
    const { bookings, loadingBookings } = useContext(BookingContext);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (loadingBookings) {
            return;
        }

        if (status === 'Approved' || status === 'Awaiting Approval') {
            setData(_.filter(bookings, { status: status }));
        } else if (status === 'Upcoming') {
            setData(_.filter(bookings, { active: true }));
        } else {
            setData(_.filter(bookings, { active: false }));
        }
        setLoading(false);
    }, [bookings, loadingBookings, status]);

    return (
        <>
            <div>
                {loading || loadingBookings ? (
                    <LoadingSection />
                ) : (
                    <>
                        <BookingList bookings={data} limit={limit || null} />
                    </>
                )}
            </div>
        </>
    );
};

export const BookingList = ({ bookings, limit }) => {
    if (bookings.length < 1) return <NoBookings />;

    if (limit) {
        bookings = _.slice(bookings, 0, limit);
    }

    return (
        <ul className="divide-y divide-neutral-200">
            {bookings.map((booking, index) => {
                let eventDate = booking.event_date.toDate();
                return (
                    <li key={index + '-' + booking.id}>
                        <Link
                            to={`/bookings/${booking.id}`}
                            className="block group hover:bg-highlight-50 rounded-lg"
                        >
                            <div className="px-4 py-4 md:px-6 flex-1">
                                <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-3">
                                    <p className="order-2 md:order-1 text-lg font-medium truncate">
                                        <span className="block text-neutral-400 text-sm">
                                            {booking.company_name}
                                        </span>
                                        {booking.event_title}
                                    </p>
                                    <div className="order-1 md:order-2 mb-3 md:mb-0 md:ml-2 flex-shrink-0 flex">
                                        <div className="flex flex-col justify-between h-full">
                                            <BookingStatus
                                                status={booking.status}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-2 md:flex md:justify-between items-end">
                                    <div>
                                        <div>
                                            <p className="flex items-center text-sm text-neutral-500 mb-3">
                                                <UserGroupIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-neutral-400"
                                                    aria-hidden="true"
                                                />
                                                {_.sumBy(
                                                    booking.event_staff,
                                                    ({ quantity }) =>
                                                        parseInt(quantity)
                                                ) || 0}{' '}
                                                Staff Required
                                            </p>
                                        </div>
                                        <div className="md:flex">
                                            <p className="flex items-center text-sm text-neutral-500">
                                                <TagIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-neutral-400"
                                                    aria-hidden="true"
                                                />
                                                {booking.event_type}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-neutral-500 md:mt-0 md:ml-6">
                                                <LocationMarkerIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-neutral-400"
                                                    aria-hidden="true"
                                                />
                                                {booking.event_location}
                                            </p>
                                            <p className="mt-2 flex items-center text-sm text-neutral-500 md:mt-0 md:ml-6">
                                                <CalendarIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-neutral-400"
                                                    aria-hidden="true"
                                                />
                                                {eventDate.toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-8 flex items-center text-xs text-neutral-300 md:mt-0">
                                        Last Updated:{' '}
                                        {booking.updated
                                            .toDate()
                                            .toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};
