import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleUser } from '../../../services/firebase-user-service';
import { LoadingSection } from '../../misc/Loading';
import { AddUser } from '../new';

export const UserEdit = () => {
    const [loading, setLoading] = useState(true);
    const [userInfo, setUserInfo] = useState({});
    const params = useParams();

    useEffect(() => {
        const user = async () => {
            let data = await getSingleUser(params.id);
            setUserInfo(data);
            setLoading(false);
        };
        user();
    }, [params.id]);

    if (loading) {
        return <LoadingSection />;
    }

    return (
        <div>
            <div className="prose text-center mx-auto">
                <h1>Amend User</h1>
            </div>
            <AddUser initial={userInfo} id={params.id} />
        </div>
    );
};
