import { useState } from 'react';
import DatePicker from 'react-datepicker';
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline';
import { Controller } from 'react-hook-form';

export const GridFieldWrapper = ({ children }) => {
    return (
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-3 sm:py-5">
            {children}
        </div>
    );
};

export const ESSTextField = ({ id, label, type, register, error, options }) => {
    return (
        <>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
            >
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <input
                        type={type}
                        {...register(id, options)}
                        className="flex-1 block w-full focus:ring-brand focus:border-brand min-w-0 rounded-md sm:text-sm border-neutral-300"
                    />
                </div>
                {error && (
                    <span className="block text-xs text-red-500 mt-3">
                        {error.message}
                    </span>
                )}
            </div>
        </>
    );
};

export const ESSPasswordField = ({ id, label, register, error, options }) => {
    const [passwordVisible, setPasswordVisible] = useState(false);

    return (
        <>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
            >
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm relative">
                    <input
                        type={passwordVisible ? 'text' : 'password'}
                        autoComplete="false"
                        {...register(id, options)}
                        className="flex-1 block w-full focus:ring-brand focus:border-brand min-w-0 rounded-md sm:text-sm border-neutral-300"
                    />
                    <button
                        className="absolute right-5 bottom-1/2 translate-y-1/2"
                        onClick={(e) => {
                            e.preventDefault();
                            setPasswordVisible(!passwordVisible);
                        }}
                    >
                        {passwordVisible ? (
                            <EyeOffIcon className="w-6 h-6" />
                        ) : (
                            <EyeIcon className="w-6 h-6" />
                        )}
                    </button>
                </div>
                {error && (
                    <span className="block text-xs text-red-500 mt-3">
                        {error.message}
                    </span>
                )}
            </div>
        </>
    );
};

export const ESSTextareaField = ({
    id,
    label,
    type,
    register,
    description,
    error,
    options,
    rows,
}) => {
    return (
        <>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
            >
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                {description && (
                    <p className="mb-2 text-sm text-neutral-500">
                        {description}
                    </p>
                )}
                <textarea
                    {...register(id, options)}
                    rows={rows || 4}
                    className="max-w-lg shadow-sm block w-full focus:ring-brand focus:border-brand sm:text-sm border border-neutral-300 rounded-md"
                />

                {error && (
                    <span className="block text-xs text-red-500 mt-3">
                        {error.message}
                    </span>
                )}
            </div>
        </>
    );
};

export const ESSDateField = ({ id, label, control, initial }) => {
    return (
        <>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
            >
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <Controller
                        name={id}
                        control={control}
                        defaultValue={initial}
                        render={({ field: { onChange, value } }) => (
                            <DatePicker
                                popperPlacement="bottom"
                                closeOnScroll={true}
                                dateFormat="dd/MM/yyyy"
                                selected={value}
                                placeholderText="Click to select a date"
                                className="flex-1 block w-full focus:ring-brand focus:border-brand min-w-0 rounded-md sm:text-sm border-neutral-300"
                                onChange={onChange}
                                calendarClassName="ess_calendar_field"
                            />
                        )}
                    />
                </div>
            </div>
        </>
    );
};

export const ESSSelectField = ({
    id,
    label,
    register,
    options,
    placeholder,
}) => {
    return (
        <>
            <label
                htmlFor={id}
                className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
            >
                {label}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div className="max-w-lg flex rounded-md shadow-sm">
                    <select
                        {...register(id)}
                        className="flex-1 block w-full focus:ring-brand focus:border-brand min-w-0 rounded-md sm:text-sm border-neutral-300"
                    >
                        <option value="" disabled>
                            {placeholder}
                        </option>
                        {options?.map((option, index) => (
                            <option
                                value={option}
                                key={`option-${index}-${option}`}
                            >
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
        </>
    );
};

export const ESSCheckboxField = ({ id, label, register, options, error }) => {
    return (
        <>
            <div className="flex items-center">
                <input type="checkbox" id={id} {...register(id, options)} />
                <label htmlFor={id} className="block ml-2">
                    {label}
                </label>
            </div>
            {error && (
                <span className="block text-xs text-red-500 mt-3">
                    {error.message}
                </span>
            )}
        </>
    );
};
