import React, { useState, useEffect, useContext } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { getSingleUser } from '../../../services/firebase-user-service';
import { LoadingSection } from '../../misc/Loading';
import { ShieldCheckIcon } from '@heroicons/react/outline';
import Section from '../../../components/Section';
import { ErrorPopup } from '../../misc/Error';

import AuthContext from '../../../context/AuthContext';
import { useSendPasswordResetEmail } from 'react-firebase-hooks/auth';
import { auth } from '../../../config/firebase';

const PasswordReset = ({ email }) => {
    const [sendPasswordResetEmail, sending, error] =
        useSendPasswordResetEmail(auth);

    if (error) {
        return <p>Error: {error.message}</p>;
    }

    if (sending) {
        return <p>Sending Reset Email...</p>;
    }

    return (
        <div>
            <button
                className="bg-brand p-3 hover:bg-sky-200"
                onClick={async () => {
                    await sendPasswordResetEmail(email);
                    alert('Email has been sent!');
                }}
            >
                Send password reset email
            </button>
        </div>
    );
};

export const UserDetails = ({ id }) => {
    const { admin } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useState({});

    useEffect(() => {
        const users = async () => {
            try {
                let data = await getSingleUser(id);
                setUser(data);
            } catch (error) {
                setError('Error: No User Found with this ID');
            }
            setLoading(false);
        };
        users();
    }, [id]);

    if (loading) {
        return <LoadingSection />;
    }
    if (error) {
        return <ErrorPopup error={error} />;
    }
    return (
        <>
            <div className="flex flex-col sm:flex-row items-center justify-start sm:justify-between mb-6">
                <div className="prose">
                    <h1 className="mb-0 text-center sm:text-left">
                        {`${user.first_name} ${user.last_name}`}
                    </h1>
                </div>
                {user.isAdmin && (
                    <div className="text-highlight-500 flex items-center mt-2 sm:mt-0">
                        <ShieldCheckIcon className="w-5 h-5 mr-2" />
                        Admin User
                    </div>
                )}
            </div>
            <Section>
                <div className="">
                    <div className="border-b border-neutral-200 pb-5">
                        <h3 className="font-bold">Personal Information</h3>
                    </div>
                    <dl className="divide-y divide-neutral-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-neutral-900">
                                Full name
                            </dt>
                            <dd className="mt-1 text-sm text-neutral-900 sm:mt-0 sm:col-span-2">
                                {`${user.first_name} ${user.last_name}`}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-neutral-900">
                                Email address
                            </dt>
                            <dd className="mt-1 text-sm text-neutral-900 sm:mt-0 sm:col-span-2">
                                {user.email}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-neutral-900">
                                Contact Number
                            </dt>
                            <dd className="mt-1 text-sm text-neutral-900 sm:mt-0 sm:col-span-2">
                                {user.telephone}
                            </dd>
                        </div>
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-neutral-900">
                                Password
                            </dt>
                            <dd className="mt-1 text-sm text-neutral-900 sm:mt-0 sm:col-span-2">
                                <PasswordReset email={user.email} />
                            </dd>
                        </div>
                    </dl>
                </div>
            </Section>
            <Section sectionStyle={`mt-6`}>
                <div className="border-b border-neutral-200 pb-5">
                    <h3 className="font-bold">Company Details</h3>
                </div>
                <dl className="divide-y divide-neutral-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-md font-bold text-neutral-900">
                            {user.company_name}
                        </dt>
                        <dd className="mt-1 text-sm text-neutral-900 sm:mt-0 sm:col-span-2">
                            <div className="flex flex-col sm:flex-row justify-between">
                                <div>
                                    <div className="whitespace-pre-line">
                                        <strong className="block mb-3">
                                            Address:
                                        </strong>
                                        {user.company_address}
                                    </div>
                                    <div className="mt-3">
                                        <strong>Telephone:</strong>{' '}
                                        {user.company_telephone}
                                    </div>
                                    <div className="mt-3">
                                        <strong>Email:</strong>{' '}
                                        {user.company_email}
                                    </div>
                                </div>
                            </div>
                        </dd>
                    </div>
                </dl>
            </Section>
            {admin && (
                <div className="mt-12 flex justify-center">
                    <Link
                        to={`/users/${id}?edit=true`}
                        className="border border-neutral-300 p-3 px-5 rounded-md"
                    >
                        Edit user
                    </Link>
                </div>
            )}
        </>
    );
};
