import React, { createContext, useEffect, useState, useContext } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../config/firebase';
import { getSingleUser } from '../services/firebase-user-service';
import AppContext from './AppContext';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const { setAppError } = useContext(AppContext);
    const [appLoading, setAppLoading] = useState(true);
    const [user, loading, error] = useAuthState(auth);
    const [userInfo, setUserInfo] = useState({});

    useEffect(() => {
        if (loading) return;

        const getUserData = async (uid) => {
            try {
                let userData = await getSingleUser(uid);
                setUserInfo(userData);
                setAppLoading(false);
            } catch (error) {
                setAppError(error);
                setAppLoading(false);
            }
        };

        if (user) {
            getUserData(user.uid);
        } else {
            setAppLoading(false);
        }

        return () => {};
    }, [user, loading, setAppError]);

    useEffect(() => {
        if (error) {
            setAppError(error);
        }
        return () => {};
    }, [error, setAppError]);

    const value = {
        user: user,
        info: userInfo,
        authenticated: user ? true : false,
        loading: appLoading,
        admin: userInfo?.isAdmin,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthContext;
