import { getBookingByDate } from '../services/firebase-booking-service';

// CALENDAR
export async function getCalendarData(date, admin = false) {
    let ROWS_COUNT = 5;
    const today = new Date();
    const initialDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const startingDate = new Date(date.getFullYear(), date.getMonth(), 1);
    startingDate.setDate(startingDate.getDate() - (startingDate.getDay() - 1));

    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    let dates = [];
    for (let i = 0; i < ROWS_COUNT * 7; i++) {
        const date = new Date(startingDate);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();
        let currentMonth = initialDate.getMonth() + 1 === month ? true : false;
        let currentDay =
            today.getMonth() + 1 === month && new Date().getDate() === day
                ? true
                : false;

        if (month < 10) {
            month = '0' + month;
        }

        if (day < 10) {
            day = '0' + day;
        }

        let formattedDate = year + '-' + month + '-' + day;
        dates.push({
            date: formattedDate,
            events: await getBookingByDate(new Date(formattedDate), admin),
            isCurrentMonth: currentMonth,
            isCurrentDay: currentDay,
            isSelected: false,
        });
        startingDate.setDate(startingDate.getDate() + 1);
    }

    return {
        label: months[initialDate.getMonth()] + ' ' + initialDate.getFullYear(),
        days: dates,
    };
}
