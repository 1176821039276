import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { useSignInWithEmailAndPassword } from 'react-firebase-hooks/auth';
import { LockClosedIcon, XCircleIcon } from '@heroicons/react/solid';
import { useForm } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { ErrorPopup } from '../misc/Error';

export const Login = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [authError, setAuthError] = useState('');

    const [signInWithEmailAndPassword, user, loading, error] =
        useSignInWithEmailAndPassword(auth);

    const navigate = useNavigate();
    const location = useLocation();

    let errorCount = Object.keys(errors).length;

    let from = location.state?.from?.pathname || '/';

    useEffect(() => {
        if (loading) return;
        if (user) {
            navigate(from, { replace: true });
        }
        // eslint-disable-next-line
    }, [user, loading]);

    useEffect(() => {
        if (error) {
            if (error.code === 'auth/network-request-failed') {
                setAuthError('Network error, please contact our team.');
            }
            if (error.code === 'auth/wrong-password') {
                setAuthError('Incorrect Password, please try again.');
            }
            if (error.code === 'auth/user-not-found') {
                setAuthError('Account not found!');
            }
        }
    }, [error]);

    const onSubmit = (data) => {
        signInWithEmailAndPassword(data.email, data.password).then(
            (response) => {
                // sessionStorage.setItem(
                //     'Auth Token',
                //     user._tokenResponse.refreshToken
                // );
            }
        );
    };

    return (
        <>
            <div className="bg-neutral-900 min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <img
                            className="mx-auto my-12 h-12 w-auto"
                            src="https://eventstaffscotland.co.uk/wp-content/themes/website-eventstaffscotland-2022/_assets/brand/header-brand-ess.svg"
                            alt="Event Staff Scotland - Client Portal"
                        />
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-white">
                            Sign in to your account
                        </h2>
                    </div>
                    <form
                        className="mt-8 space-y-6"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <input
                            type="hidden"
                            name="remember"
                            defaultValue="true"
                        />
                        <div className="rounded-md shadow-sm -space-y-px">
                            <div>
                                <label htmlFor="email" className="sr-only">
                                    Email address
                                </label>
                                <input
                                    {...register('email', {
                                        required:
                                            'An email address is required',
                                    })}
                                    placeholder="Email Address"
                                    type="email"
                                    className={`appearance-none bg-neutral-700 text-center rounded-none relative block w-full px-3 py-2 border border-neutral-600 placeholder-neutral-500 text-white rounded-t-md focus:outline-none active:outline-none sm:text-sm ${
                                        errors.email && 'border-red-500'
                                    }`}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="sr-only">
                                    Password
                                </label>
                                <input
                                    {...register('password', {
                                        required: 'Enter your password',
                                    })}
                                    placeholder="password"
                                    type="password"
                                    className={`appearance-none bg-neutral-700 text-center rounded-none relative block w-full px-3 py-2 border border-neutral-600 placeholder-neutral-500 text-white rounded-b-md focus:outline-none focus:border-0 sm:text-sm ${
                                        errors.password && 'border-red-500'
                                    }`}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-neutral-900 bg-brand hover:bg-brand focus:outline-none focus:ring-0"
                            >
                                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                                    <LockClosedIcon
                                        className="h-5 w-5 text-neutral-900 group-hover:text-highlight-500"
                                        aria-hidden="true"
                                    />
                                </span>
                                Sign in
                            </button>
                        </div>
                        {authError && <ErrorPopup error={authError} />}
                        {errorCount >= 1 && (
                            <div className="rounded-md bg-red-50 p-4">
                                <div className="flex">
                                    <div className="flex-shrink-0">
                                        <XCircleIcon
                                            className="h-5 w-5 text-red-400"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="ml-3">
                                        <h3 className="text-sm font-medium text-red-800">
                                            Please fix the following errors:
                                        </h3>
                                        <div className="mt-2 text-sm text-red-700">
                                            <ul className="list-disc pl-5 space-y-1">
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="email"
                                                    as={
                                                        <li className="text-sm" />
                                                    }
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="password"
                                                    as={
                                                        <li className="text-sm" />
                                                    }
                                                />
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex items-center justify-center">
                            <div className="text-xs">
                                <Link
                                    to="/reset-password"
                                    className="font-medium text-brand hover:text-brand"
                                >
                                    Forgot your password?
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
