import {
    collection,
    doc,
    setDoc,
    getDoc,
    getDocs,
    deleteDoc,
    orderBy,
    query,
    updateDoc,
} from 'firebase/firestore';
import { auth, db } from '../config/firebase';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../config/firebase';
import { getAllEventsByUser } from './firebase-booking-service';

// GET ALL USERS
const getAllUsers = async () => {
    const q = query(collection(db, 'users'), orderBy('last_name', 'asc'));
    const querySnapshot = await getDocs(q);
    let users = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        uid: doc.id,
        id: doc.id,
    }));
    return users;
};

// GET SINGLE USER
const getSingleUser = async (id) => {
    let isAdmin = false;
    let tokenResult = await auth.currentUser.getIdTokenResult();
    isAdmin = tokenResult.claims.admin ? true : false;

    const docRef = doc(db, 'users', id);
    const docSnap = await getDoc(docRef);
    if (!docSnap.data()) {
        throw new Error('No user found with this id');
    }
    let user = { ...docSnap.data(), isAdmin: isAdmin };
    return user;
};

// ADD USER
const addNewUser = async (user) => {
    try {
        const callable = httpsCallable(functions, 'addNewUser');
        let newUser = await callable({
            email: user.email,
            password: user.password,
            displayName: `${user.first_name} ${user.last_name}`,
        });
        delete user['password'];
        let userRecord = await setDoc(doc(db, 'users', newUser.data.uid), user);
        return userRecord;
    } catch (error) {
        console.error(error);
    }
};

// UPDATE USER
const updateUser = async (id, user) => {
    try {
        const updateFirebaseUser = httpsCallable(
            functions,
            'updateFirebaseUser'
        );
        delete user['uid'];
        await updateFirebaseUser({
            id: id,
            user: {
                email: user.email,
                displayName: `${user.first_name} ${user.last_name}`,
            },
        });
        await updateDoc(doc(db, 'users', id), user);
    } catch (error) {
        console.error(error);
        throw new Error('Error updating User!');
    }
};

// DELETE USER
const deleteUser = async (id) => {
    const deleteFirebaseUser = httpsCallable(functions, 'deleteUser');
    await deleteFirebaseUser({ id: id });
    await deleteDoc(doc(db, 'users', id));
    const allEvents = await getAllEventsByUser(id);
    allEvents.forEach(async (booking) => {
        await deleteDoc(doc(db, 'bookings', booking.id));
    });
};

const generateUserPassword = (length) => {
    let result = '';
    let characters =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
        );
    }
    return result;
};

export {
    getAllUsers,
    getSingleUser,
    addNewUser,
    updateUser,
    deleteUser,
    generateUserPassword,
};
