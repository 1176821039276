import React, { useState, Fragment, useContext } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { cancelBooking } from '../../../services/firebase-booking-service';
import { Link, useNavigate } from 'react-router-dom';
import Section from '../../../components/Section';
import AuthContext from '../../../context/AuthContext';
import {
    BookingStatus,
    BookingStatusSwitch,
} from '../../../components/BookingStatus';

import { BookingNotes } from './BookingNotes';

const TextDetailRow = ({ title, data }) => (
    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-center sm:border-t sm:border-neutral-200 sm:pt-5">
        <label className="block text-sm font-medium text-gray-900 sm:mt-px sm:pt-2">
            {title}
        </label>
        <div className="mt-1 sm:mt-0 sm:col-span-2">
            <div className="block w-full text-sm sm:mt-px sm:pt-2">{data}</div>
        </div>
    </div>
);

const StaffList = ({ staffList }) => {
    return (
        <>
            <div className="grid grid-cols-9 pb-2 max-w-lg">
                <div className="col-span-1 text-xs text-center">Qty</div>
                <div className="col-span-7 text-xs">Description</div>
                <div className="col-span-1"></div>
            </div>
            <ul className="max-w-lg">
                {staffList?.map((item, index) => (
                    <li
                        key={`staffline-${index}`}
                        className="grid grid-cols-9 items-center border-t border-neutral-200 py-3"
                    >
                        <div className="col-span-1 text-center">
                            {item.quantity}
                        </div>
                        <div className="col-span-7">{item.description}</div>
                        <div className="col-span-1 pr-2"></div>
                    </li>
                ))}
            </ul>
        </>
    );
};

export const ShowBookingDetails = ({ booking, id }) => {
    const { admin } = useContext(AuthContext);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const navigate = useNavigate();

    const handleBookingCancel = async () => {
        await cancelBooking(id, admin);
        navigate('/bookings', { replace: true });
    };

    return (
        <div className="max-w-4xl mx-auto relative">
            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between text-center sm:text-left">
                <div className="order-2 sm:order-1">
                    <h3 className="text-xl leading-6 font-medium text-neutral-900">
                        Booking Details
                    </h3>
                    <p className="mt-3 text-xs text-neutral-400">
                        Created: {booking.created.toDate().toLocaleString()}
                        <br />
                        Last Updated:{' '}
                        {booking.updated.toDate().toLocaleString()}
                    </p>
                </div>

                <div className="sm:flex items-center order-1 sm:order-2 mb-3">
                    <div className="mx-auto my-4 sm:mr-3 order-2 sm:order-1 text-center">
                        {admin ? (
                            <BookingStatusSwitch
                                status={booking.status}
                                admin={admin}
                                id={id}
                            />
                        ) : (
                            <BookingStatus
                                status={booking.status}
                                size="large"
                            />
                        )}
                    </div>
                </div>
            </div>

            <Section sectionStyle="mt-12 sm:mt-6">
                <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                    <div>
                        <div>
                            <h3 className="text-lg leading-6 font-medium text-neutral-900">
                                Event Information
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-neutral-500">
                                Please be as accurate as possible to ensure your
                                booking is correctly understood.
                            </p>
                        </div>

                        <div className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                            <TextDetailRow
                                title={`Event Title`}
                                data={booking.event_title}
                            />
                            <TextDetailRow
                                title={`Event Location`}
                                data={booking.event_location}
                            />
                            <TextDetailRow
                                title={`Event Type`}
                                data={booking.event_type}
                            />
                            <TextDetailRow
                                title={`Staff Required`}
                                data={
                                    <StaffList
                                        staffList={booking.event_staff}
                                    />
                                }
                            />
                            <TextDetailRow
                                title={`Event Date`}
                                data={booking.event_date
                                    .toDate()
                                    .toLocaleString('en-GB', {
                                        weekday: 'long',
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    })}
                            />
                            <TextDetailRow
                                title={`Event Details`}
                                data={booking.event_details || 'N/A'}
                            />
                            <TextDetailRow
                                title={`Additional Comments`}
                                data={
                                    booking.event_additional_comments || 'N/A'
                                }
                            />
                        </div>
                    </div>
                </div>
            </Section>

            <Section sectionStyle="mt-6">
                <div className="space-y-6 sm:space-y-5">
                    <div>
                        <h3 className="text-lg leading-6 font-medium text-neutral-900">
                            Contact Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-neutral-500">
                            Please update this information to the most useful
                            contact for this event.
                        </p>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                        <TextDetailRow
                            title={`Company`}
                            data={booking.company_name}
                        />
                        <TextDetailRow
                            title={`Name of Contact`}
                            data={booking.contact_name}
                        />
                        <TextDetailRow
                            title={`Email Address`}
                            data={booking.contact_email}
                        />
                    </div>
                </div>
            </Section>

            <BookingNotes admin={admin} notes={booking.booking_notes} id={id} />

            {booking.active === true || admin ? (
                <Section sectionStyle="mt-6">
                    <h3 className="text-lg leading-6 font-medium text-neutral-900">
                        Need to amend your booking?
                    </h3>
                    <div className="mt-2 max-w-xl text-sm text-neutral-500">
                        <p>
                            Please use the edit booking button below to amend
                            your booking. Please not that any changes to
                            "Approved" bookings will require the booking to be
                            re-approved by the Event Staff Scotland team.
                        </p>
                    </div>
                    <div className="mt-5 sm:mt-8 flex flex-col sm:flex-row justify-center sm:justify-between">
                        <div className="flex">
                            <Link
                                to={`/bookings/${id}?edit=true`}
                                className="flex-1 px-6 py-3 text-sm font-medium rounded-md text-gray-900 bg-brand hover:bg-highlight-200 border-brand border"
                            >
                                Edit Booking
                            </Link>
                            <Link
                                to={`/bookings/create?from=${id}`}
                                className="flex-1 px-6 py-3 ml-3 text-sm font-medium rounded-md text-neutral-300 border-neutral-300 border whitespace-nowrap hover:text-neutral-500"
                            >
                                Duplicate Booking
                            </Link>
                        </div>
                        {booking.status !== 'Cancelled' && (
                            <button
                                type="button"
                                onClick={() => setDeleteDialog(true)}
                                // className="order-2 inline-flex text-sm items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-white hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                                className="border border-red-500 text-red-500 text-sm px-6 py-3 rounded-md mt-5 sm:mt-0 hover:bg-red-500 hover:text-white"
                            >
                                Cancel Booking
                            </button>
                        )}
                    </div>
                </Section>
            ) : null}

            <Transition.Root show={deleteDialog} as={Fragment}>
                <Dialog
                    as="div"
                    className="fixed z-10 inset-0 overflow-y-auto"
                    onClose={() => setDeleteDialog(false)}
                >
                    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-neutral-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="hidden sm:inline-block sm:align-middle sm:h-screen"
                            aria-hidden="true"
                        >
                            &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationIcon
                                            className="h-6 w-6 text-red-600"
                                            aria-hidden="true"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg leading-6 font-medium text-neutral-900"
                                        >
                                            Cancel Booking
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            {!admin ? (
                                                <p className="text-sm text-neutral-500">
                                                    Are you sure you want to
                                                    cancel your booking? Your
                                                    booking will be placed under
                                                    "Pending Cancellation" until
                                                    a member of our team has
                                                    approved it as per our terms
                                                    & conditions.
                                                </p>
                                            ) : (
                                                <p className="text-sm text-neutral-500">
                                                    Are you sure you want to
                                                    cancel this booking?
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => handleBookingCancel()}
                                    >
                                        {!admin
                                            ? 'Request Cancellation'
                                            : 'Cancel Booking'}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-neutral-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-neutral-700 hover:bg-neutral-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => setDeleteDialog(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </div>
    );
};
