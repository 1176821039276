import { Link } from 'react-router-dom';

export const NoBookingsCreate = () => {
    return (
        <div className="bg-white">
            <Link
                to="/bookings/create"
                type="button"
                className="relative block w-full border-2 border-neutral-300 border-dashed rounded-lg p-12 text-center hover:border-neutral-400 focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-12 w-12 text-neutral-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                </svg>
                <span className="mt-2 block text-sm font-medium text-neutral-900">
                    Create new booking
                </span>
            </Link>
        </div>
    );
};

export const NoBookings = () => {
    return (
        <div className="bg-white">
            <div className="relative block w-full border-2 border-neutral-300 border-dashed rounded-lg p-12 text-center focus:outline-none">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mx-auto h-12 w-12 text-neutral-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                    />
                </svg>
                <span className="mt-2 block text-sm font-medium text-neutral-900">
                    No Bookings to Display
                </span>
            </div>
        </div>
    );
};
