import React from 'react';
import { XCircleIcon } from '@heroicons/react/solid';

export const ErrorScreen = ({ error }) => {
    return (
        <div className="flex h-screen items-center justify-center w-full bg-red-800">
            <div className="prose prose-invert text-center">
                <h1 className="mb-0">Uh Oh!</h1>
                <p className="mt-3">
                    We're sorry but there has been an error.
                    <br />
                    We're working on fixing this issue as soon as possible.
                </p>
                <pre className="text-left mt-6">
                    {JSON.stringify(error.message || {}, null, '\t')}
                </pre>
            </div>
        </div>
    );
};

export const ErrorPopup = ({ error }) => {
    return (
        <div className="rounded-md bg-red-50 border border-red-500 p-4">
            <div className="flex">
                <div className="flex-shrink-0">
                    <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <h3 className="text-sm font-medium text-red-800">
                        {error}
                    </h3>
                </div>
            </div>
        </div>
    );
};
