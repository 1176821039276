import React from 'react';

export const Loading = ({ scheme }) => {
    return (
        <div
            className={`flex h-screen items-center justify-center w-full ${
                scheme === 'dark' ? 'bg-slate-900' : 'bg-neutral-100'
            }`}
        >
            <div className="animate-spin border-2 border-slate-300 border-solid inline-block w-[30px] h-[30px] rounded-[30px] border-l-4 border-l-highlight-500"></div>
        </div>
    );
};

export const LoadingSection = () => {
    return (
        <div
            className={`flex min-h-[200px] items-center justify-center w-full`}
        >
            <div className="animate-spin border-2 border-slate-300 border-solid inline-block w-[30px] h-[30px] rounded-[30px] border-l-4 border-l-highlight-500"></div>
        </div>
    );
};
