import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { updateBookingStatus } from '../services/firebase-booking-service';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const statusColours = {
    'Awaiting Approval': {
        text: 'text-orange-800',
        bg: 'bg-orange-100',
        strongBG: 'bg-orange-300',
        lightBG: 'bg-orange-50',
    },
    Approved: {
        text: 'text-green-800',
        bg: 'bg-green-100',
        strongBG: 'bg-green-300',
        lightBG: 'bg-green-50',
    },
    Completed: {
        text: 'text-neutral-800',
        bg: 'bg-neutral-100',
        strongBG: 'bg-neutral-300',
        lightBG: 'bg-neutral-50',
    },
    Cancelled: {
        text: 'text-red-800',
        bg: 'bg-red-100',
        strongBG: 'bg-red-300',
        lightBG: 'bg-red-50',
    },
    'Pending Cancellation': {
        text: 'text-red-800',
        bg: 'bg-red-100',
        strongBG: 'bg-red-300',
        lightBG: 'bg-red-50',
    },
};

export const BookingStatus = ({ status, size }) => {
    return (
        <p
            className={`inline-flex leading-5 font-semibold rounded-full ${
                statusColours[status].text
            } ${statusColours[status].bg} ${
                size === 'large' ? `text-xl px-5 py-3` : `text-xs px-2 `
            }`}
        >
            {status}
        </p>
    );
};

export const BookingStatusSwitch = ({ status, admin = false, id }) => {
    const [isLoading, setIsLoading] = useState(false);

    const items = [
        { name: 'Awaiting Approval', style: 'bg-orange-300' },
        { name: 'Approved', style: 'bg-green-300' },
        { name: 'Completed', style: 'bg-neutral-300' },
        { name: 'Pending Cancellation', style: 'bg-red-500' },
        { name: 'Cancelled', style: 'bg-red-500' },
    ];

    const onStatusClick = async (clickedStatus) => {
        setIsLoading(true);
        await updateBookingStatus(clickedStatus, id, admin);
        setIsLoading(false);
    };

    if (isLoading)
        return (
            <span className="relative z-0 inline-flex shadow-sm rounded-md">
                <div
                    className={`relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700`}
                >
                    Loading...
                </div>
            </span>
        );

    return (
        <span className="relative z-0 inline-flex shadow-sm rounded-md">
            <div
                className={`relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 ${statusColours[status].text} ${statusColours[status].bg} text-sm font-medium`}
            >
                {status}
            </div>
            <Menu as="span" className="-ml-px relative block">
                <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500">
                    <span className="sr-only">Open options</span>
                    <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Menu.Button>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-white ring-opacity-5 focus:outline-none">
                        <div className="py-1 divide-y ">
                            {items.map((item) => (
                                <Menu.Item key={item.name}>
                                    {({ active }) => (
                                        <button
                                            onClick={() =>
                                                onStatusClick(item.name)
                                            }
                                            className={classNames(
                                                active
                                                    ? 'bg-gray-100 text-gray-900'
                                                    : 'text-gray-700',
                                                'flex items-center px-4 py-2 text-sm w-full text-right'
                                            )}
                                        >
                                            <div
                                                className={`w-1.5 h-1.5 rounded-full mr-2 ${item.style}`}
                                            ></div>
                                            {item.name}
                                        </button>
                                    )}
                                </Menu.Item>
                            ))}
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
        </span>
    );
};
