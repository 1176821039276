import { useContext, useEffect } from 'react';
import AppContext from '../../context/AppContext';
import AuthContext from '../../context/AuthContext';
import { BookingContextProvider } from '../../context/BookingContext';
import { ErrorScreen } from '../misc/Error';

import { AdminArea, ClientArea } from './Area';

export const MainApplication = () => {
    const { user, loading, admin } = useContext(AuthContext);
    const { appError } = useContext(AppContext);

    useEffect(() => {
        document.querySelector('body').classList.add('bg-neutral-100');
    }, []);

    if (appError) {
        return <ErrorScreen error={appError} />;
    }

    return (
        <BookingContextProvider user={user} admin={admin}>
            {appError && <div>Error</div>}
            {loading && <div>Loading</div>}
            {admin ? <AdminArea /> : <ClientArea />}
        </BookingContextProvider>
    );
};
