import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import {
    createBooking,
    updateBooking,
} from '../../../services/firebase-booking-service';
import AuthContext from '../../../context/AuthContext';
import Section from '../../../components/Section';
import {
    ExclamationCircleIcon,
    XIcon,
    PlusIcon,
} from '@heroicons/react/outline';
import { UserSelect } from '../../../components/UserSelect';

// FIELDS
import {
    GridFieldWrapper,
    ESSCheckboxField,
    ESSDateField,
    ESSSelectField,
    ESSTextareaField,
    ESSTextField,
} from '../../../components/Fields';

import 'react-datepicker/dist/react-datepicker.css';

const AddStaffToList = ({ staffList, setStaffList }) => {
    const [quantity, setQuantity] = useState('');
    const [description, setDescription] = useState('');

    const submitRow = (e) => {
        e.preventDefault();
        if (quantity && description) {
            setStaffList([
                ...staffList,
                {
                    quantity: quantity,
                    description: description,
                },
            ]);
            setQuantity('');
            setDescription('');
        }
    };

    return (
        <div className="grid grid-cols-9 gap-1 items-start py-3 sm:py-5 max-w-lg">
            <div className="col-span-1">
                <input
                    type="text"
                    placeholder="1"
                    value={quantity}
                    onChange={(evt) => setQuantity(evt.target.value)}
                    className="flex-1 block w-full text-center focus:ring-highlight-500 focus:border-highlight-500 min-w-0 rounded-md text-sm border-neutral-300"
                />
            </div>
            <div className="col-span-7">
                <input
                    type="text"
                    placeholder="Job Title"
                    value={description}
                    onChange={(evt) => setDescription(evt.target.value)}
                    className="flex-1 block w-full focus:ring-highlight-500 focus:border-highlight-500 min-w-0 rounded-md text-sm border-neutral-300"
                />
            </div>
            <div className="col-span-1 pr-2">
                <div
                    className="flex-1 flex justify-center items-center w-full min-w-0 rounded-md text-sm border border-neutral-300 h-[38px] hover:bg-green-500 group"
                    onClick={(e) => submitRow(e)}
                >
                    <PlusIcon className="w-4 h-4 text-green-500 group-hover:text-white" />
                </div>
            </div>
        </div>
    );
};

const StaffList = ({ staffList, setStaffList }) => {
    return (
        <>
            <div className="grid grid-cols-9 border-b border-b-neutral-200 pb-2 max-w-lg">
                <div className="col-span-1 text-xs text-center">Qty</div>
                <div className="col-span-7 text-xs">Description</div>
                <div className="col-span-1"></div>
            </div>
            <ul className="max-w-lg">
                {staffList.map((item, index) => (
                    <li
                        key={`staffline-${index}`}
                        className="grid grid-cols-9 items-center border-b border-neutral-200 py-3"
                    >
                        <div className="col-span-1 text-center">
                            {item.quantity}
                        </div>
                        <div className="col-span-7">{item.description}</div>
                        {setStaffList && (
                            <div className="col-span-1 pr-2">
                                <XIcon
                                    className="w-4 h-4 text-neutral-300 hover:text-neutral-600 ml-auto"
                                    onClick={() => {
                                        setStaffList(
                                            staffList.filter(
                                                (element) => element !== item
                                            )
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
};

export const BookingForm = ({ initial, edit, id }) => {
    const { loading, admin } = useContext(AuthContext);
    const [formLoading, setFormLoading] = useState(false);
    const [staffList, setStaffList] = useState(initial.event_staff || []);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty },
    } = useForm({
        defaultValues: initial,
    });

    const navigate = useNavigate();

    useEffect(() => {
        setValue('event_staff', staffList, { shouldDirty: true });
    }, [setValue, staffList]);

    const handleConnectedUser = (id) => {
        setValue('uid', id, { shouldDirty: true });
    };

    const submitEvent = async (data) => {
        setFormLoading(true);
        if (edit) {
            try {
                await updateBooking(data, id, admin);
                setTimeout(() => {
                    setFormLoading(false);
                    navigate('/bookings', { replace: true });
                }, 1000);
            } catch (error) {
                setFormLoading(false);
                console.error(error);
            }
        } else {
            try {
                await createBooking(data);
                setTimeout(() => {
                    setFormLoading(false);
                    navigate('/bookings', { replace: true });
                }, 1000);
            } catch (error) {
                setFormLoading(false);
                console.error(error);
            }
        }
    };

    let buttonText =
        admin && !edit
            ? 'Create Booking'
            : admin && edit
            ? 'Amend Booking'
            : edit
            ? 'Request Booking Update'
            : 'Request Booking';

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <>
                    <form
                        className="space-y-8"
                        onSubmit={handleSubmit(submitEvent)}
                    >
                        <Section>
                            <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-neutral-900">
                                        Event Information
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-neutral-500">
                                        Please be as accurate as possible to
                                        ensure your booking is correctly
                                        understood.
                                    </p>
                                </div>
                                <div className="divide-y divide-neutral-200">
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Event Title"
                                            id="event_title"
                                            type="text"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please give your booking at title',
                                            }}
                                            error={errors.event_title}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Event Location & Postcode"
                                            id="event_location"
                                            type="text"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please tell us your booking location',
                                            }}
                                            error={errors.event_location}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSSelectField
                                            id="event_type"
                                            label="Event Type"
                                            register={register}
                                            initial={initial.event_type}
                                            placeholder="Select event type..."
                                            options={[
                                                'Private Event (Birthday, Party, Celebration, etc)',
                                                'Wedding',
                                                'Conference / Corporate Event',
                                                'Exhibition / Trade Fair',
                                                'Promotional Event',
                                                'Other (Please Specify in Event Details)',
                                            ]}
                                        />
                                    </GridFieldWrapper>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-3 sm:py-5">
                                        <label
                                            htmlFor=""
                                            className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
                                        >
                                            Staff Required
                                        </label>
                                        <div className="col-span-2 block text-sm sm:mt-px sm:pt-2">
                                            {staffList.length > 0 ? (
                                                <StaffList
                                                    staffList={staffList}
                                                    setStaffList={setStaffList}
                                                />
                                            ) : (
                                                <>
                                                    <div className="grid grid-cols-9 border-b border-b-neutral-200 pb-2 mb-2">
                                                        <div className="col-span-1 text-xs text-center">
                                                            Qty
                                                        </div>
                                                        <div className="col-span-7 text-xs">
                                                            Description
                                                        </div>
                                                        <div className="col-span-1"></div>
                                                    </div>
                                                    <div className="grid grid-cols-9">
                                                        <div className="col-span-9 text-xs opacity-50 text-center p-5">
                                                            No Staff Added.
                                                            Please add staff
                                                            with the + button
                                                            below.
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                            <AddStaffToList
                                                staffList={staffList}
                                                setStaffList={setStaffList}
                                            />
                                        </div>
                                    </div>
                                    <input
                                        type="hidden"
                                        {...register('event_staff')}
                                    />
                                    <GridFieldWrapper>
                                        <ESSDateField
                                            id="event_date"
                                            label="Event Date"
                                            defaultValue={initial.event_date}
                                            control={control}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextareaField
                                            label="Event Details & Timings"
                                            id="event_details"
                                            type="text"
                                            register={register}
                                            error={errors.event_details}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextareaField
                                            label="Additional Comments"
                                            id="event_additional_comments"
                                            type="text"
                                            register={register}
                                            error={errors.event_details}
                                        />
                                    </GridFieldWrapper>
                                </div>
                            </div>
                        </Section>

                        <Section>
                            <div className="space-y-8 divide-y divide-neutral-200 sm:space-y-5">
                                <div>
                                    <h3 className="text-lg leading-6 font-medium text-neutral-900">
                                        Contact Information
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-neutral-500">
                                        Please update this information to the
                                        most useful contact for this event.
                                    </p>
                                </div>
                                <div className="divide-y divide-neutral-200">
                                    {admin && (
                                        <>
                                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-3 sm:py-5">
                                                <label
                                                    htmlFor="uid"
                                                    className="block text-sm font-medium text-neutral-700 sm:mt-px sm:pt-2"
                                                >
                                                    Connected User
                                                </label>
                                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                                    <div className="max-w-lg flex rounded-md shadow-sm">
                                                        <input
                                                            type="hidden"
                                                            {...register('uid')}
                                                            className="flex-1 block w-full focus:ring-brand focus:border-brand min-w-0 rounded-md sm:text-sm border-neutral-300"
                                                        />
                                                        <UserSelect
                                                            user={
                                                                initial.uid ??
                                                                null
                                                            }
                                                            handleChange={
                                                                handleConnectedUser
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Company Name"
                                            id="company_name"
                                            type="text"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please enter the company name for this booking',
                                            }}
                                            error={errors.company_name}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Contact Name"
                                            id="contact_name"
                                            type="text"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please enter the contact name for this booking',
                                            }}
                                            error={errors.contact_name}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Contact Email"
                                            id="contact_email"
                                            type="email"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please enter a contact email for this booking',
                                            }}
                                            error={errors.contact_email}
                                        />
                                    </GridFieldWrapper>
                                    <GridFieldWrapper>
                                        <ESSTextField
                                            label="Contact Phone"
                                            id="contact_telephone"
                                            type="tel"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please enter a contact phone number for this booking',
                                            }}
                                            error={errors.contact_telephone}
                                        />
                                    </GridFieldWrapper>
                                </div>
                            </div>
                        </Section>

                        <Section>
                            <div className="flex">
                                <div className="divide-y divide-gray-200">
                                    <div className="py-5 flex">
                                        <ExclamationCircleIcon className="h-6 w-6 mr-2 text-red-500" />
                                        <h3 className="text-lg leading-6 font-medium text-neutral-900 flex">
                                            Important Information
                                        </h3>
                                    </div>
                                    <div className="py-5">
                                        <p className="text-sm mt-3">
                                            By completing this form and checking
                                            the box below, you are accepting the
                                            contents in our terms & conditions
                                            document, available by clicking{' '}
                                            <a
                                                className="font-bold underline"
                                                href="https://eventstaffscotland.co.uk/wp-content/uploads/2021/09/EventStaffScotland_ClientTermsandCondition_2021.pdf"
                                                target="_BLANK"
                                                rel="noreferrer"
                                            >
                                                here
                                            </a>
                                            . By submitting the form you agree
                                            to all conditions in regards to this
                                            booking and understand that by doing
                                            so your booking is confirmed and
                                            subject to our cancellation policy
                                            described in the{' '}
                                            <a
                                                className="font-bold underline"
                                                href="https://eventstaffscotland.co.uk/wp-content/uploads/2021/09/EventStaffScotland_ClientTermsandCondition_2021.pdf"
                                                target="_BLANK"
                                                rel="noreferrer"
                                            >
                                                terms & conditions document
                                            </a>
                                            .
                                        </p>
                                    </div>
                                    <div className="py-5">
                                        <ESSCheckboxField
                                            id="tandc_acceptance"
                                            label="I agree to the terms & conditions"
                                            register={register}
                                            options={{
                                                required:
                                                    'Please accept the terms & conditions',
                                            }}
                                            error={errors.tandc_acceptance}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Section>

                        <div className="bg-red-50 rounded-lg p-5 border border-red-300">
                            <h4 className="text-md font-medium mb-3 text-red-500">
                                Covid Notice
                            </h4>
                            <p className="text-sm">
                                Event Staff Scotland has provided safe working
                                guidelines for our temporary staff to use during
                                the COVID-19 pandemic. We request that a safe
                                working environment be provided for the staff,
                                and that you do not ask them to do anything that
                                would contravene current government guidelines.
                            </p>
                        </div>

                        <Section>
                            <div className="flex justify-end">
                                <button
                                    type="submit"
                                    disabled={formLoading || !isDirty}
                                    className={`w-full justify-center flex items-center h-12 border border-transparent shadow-sm font-medium rounded-md text-white bg-green-500 disabled:opacity-20 disabled:hover:bg-green-500 ${
                                        formLoading
                                            ? 'hover:bg-green-500'
                                            : 'hover:bg-green-600'
                                    }`}
                                >
                                    {formLoading ? (
                                        <span className="animate-spin rounded-full border-2 border-t-2 border-t-highlight-700 border-white w-6 h-6 inline-block"></span>
                                    ) : (
                                        buttonText
                                    )}
                                </button>
                            </div>
                        </Section>
                    </form>
                </>
            )}
        </>
    );
};
