import { useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import { MenuIcon, PlusIcon, XIcon } from '@heroicons/react/outline';

import { logout } from '../services/firebase-auth-service';
import AuthContext from '../context/AuthContext';
import BookingContext from '../context/BookingContext';

const Header = ({ navigation }) => {
    const { user, admin } = useContext(AuthContext);
    const { bookingCount } = useContext(BookingContext);

    return (
        <Disclosure
            as="nav"
            className="bg-neutral-900 fixed lg:relative z-40 w-full shadow lg:bg-white"
        >
            {({ open }) => (
                <>
                    <div
                        className={`mx-auto relative z-20 md:border-b-0 ${
                            open && 'border-b border-b-neutral-800'
                        }`}
                    >
                        <div className="flex justify-between lg:justify-end h-16 align-center">
                            {/* Menu Button */}
                            <div className="flex lg:hidden items-center border-r border-r-neutral-800">
                                <div className="flex items-center">
                                    <Disclosure.Button className="inline-flex items-center justify-center p-4 md:px-8 rounded-md text-white">
                                        <span className="sr-only">
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <MenuIcon
                                                className="block h-6 w-6"
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                            {/* Branding */}
                            <div className="flex px-4 lg:hidden">
                                <div className="flex-shrink-0 flex items-center">
                                    <img
                                        className="block h-6 w-auto"
                                        src="https://eventstaffscotland.co.uk/wp-content/themes/website-eventstaffscotland-2022/_assets/brand/header-brand-ess.svg"
                                        alt="Event Staff Scotland"
                                    />
                                </div>
                            </div>
                            {/* New Job */}
                            <div className="flex items-center">
                                <div className="flex-shrink-0">
                                    <div className="flex items-center">
                                        <a
                                            className="inline-flex h-16 items-center p-4 md:px-8 bg-brand text-gray-900  hover:bg-highlight-200"
                                            href="/bookings/create"
                                        >
                                            <div className="hidden md:block text-sm mr-2">
                                                {admin
                                                    ? 'Add Booking'
                                                    : 'Request Booking'}
                                            </div>
                                            <PlusIcon className="block h-6 w-6" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Mobile Navigation */}
                    <Disclosure.Panel className="fixed lg:hidden w-full md:max-w-xs bg-neutral-900 h-screen top-0 z-10 pt-16">
                        <div className="flex flex-col space-between h-full justify-between">
                            <div className="flex-1">
                                {navigation.map((item, index) => (
                                    <Disclosure.Button
                                        as="a"
                                        key={`mobile-nav-item-${index}`}
                                        href={item.href}
                                        className="block text-white p-4 sm:px-6 border-b border-b-neutral-800"
                                    >
                                        {item.name}
                                        {item.name === 'Bookings' ? (
                                            <span className="inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium bg-brand text-gray-900">
                                                {bookingCount}
                                            </span>
                                        ) : null}
                                    </Disclosure.Button>
                                ))}
                            </div>
                            <div>
                                <div className="border-t border-t-neutral-800">
                                    <Disclosure.Button
                                        as="a"
                                        className="flex items-center p-4 sm:px-6"
                                        href={`/profile`}
                                    >
                                        {user.imageUrl ? (
                                            <img
                                                className="inline-block h-10 w-10 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            />
                                        ) : (
                                            <span className="inline-block h-10 w-10 rounded-full overflow-hidden bg-neutral-100">
                                                <svg
                                                    className="h-full w-full text-neutral-300"
                                                    fill="currentColor"
                                                    viewBox="0 0 24 24"
                                                >
                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                </svg>
                                            </span>
                                        )}

                                        <div className="ml-3">
                                            <div className="text-base font-medium text-neutral-300">
                                                {user.displayName}
                                            </div>
                                            <div className="text-sm font-medium text-neutral-500">
                                                {user.email}
                                            </div>
                                        </div>
                                    </Disclosure.Button>
                                </div>
                                <div className="border-t border-t-neutral-800">
                                    <button
                                        onClick={logout}
                                        className="block text-center p-4 sm:px-6 w-full text-neutral-500"
                                    >
                                        Sign out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </Disclosure.Panel>
                </>
            )}
        </Disclosure>
    );
};

export default Header;
