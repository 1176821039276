import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';

const firebaseConfig = {
    apiKey: 'AIzaSyAvRRzcGtKoI8I1kyOUqG_niV-12H0oVTM',
    authDomain: 'ess---client-portal.firebaseapp.com',
    projectId: 'ess---client-portal',
    storageBucket: 'ess---client-portal.appspot.com',
    messagingSenderId: '508717002191',
    appId: '1:508717002191:web:94175f8afa93c6a55bf6ba',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(app);
if (window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099', {
        disableWarnings: true,
    });
}

const db = getFirestore(app);
if (window.location.hostname === 'localhost') {
    connectFirestoreEmulator(db, 'localhost', 8080);
}

const functions = getFunctions(app);
if (window.location.hostname === 'localhost') {
    connectFunctionsEmulator(functions, 'localhost', 5001);
}

export { app, db, auth, functions, analytics };
