import React from 'react';
import { useParams } from 'react-router-dom';
import { useDocumentDataOnce } from 'react-firebase-hooks/firestore';
import { doc, query } from 'firebase/firestore';
import { db } from '../../../config/firebase';
import { ErrorPopup } from '../../misc/Error';
import { Loading } from '../../misc/Loading';
import { BookingForm } from '../create/BookingForm';

export const EditBooking = () => {
    const params = useParams();
    const q = query(doc(db, 'bookings', params.id));
    const [snapshot, loading, error] = useDocumentDataOnce(q);

    return (
        <>
            {error && <ErrorPopup error={error.message} />}
            {loading || error || !snapshot ? (
                <Loading />
            ) : (
                <>
                    <div>
                        <BookingForm
                            initial={{
                                ...snapshot,
                                event_date: snapshot.event_date.toDate(),
                            }}
                            edit={true}
                            id={params.id}
                        />
                    </div>
                </>
            )}
        </>
    );
};
