import {
    CalendarIcon,
    CollectionIcon,
    UserIcon,
    ViewGridIcon,
} from '@heroicons/react/outline';

export const navigation = [
    { name: 'Dashboard', href: '/', icon: ViewGridIcon },
    { name: 'Bookings', href: '/bookings', icon: CollectionIcon },
    { name: 'Calendar', href: '/calendar', icon: CalendarIcon },
];

export const adminNavigation = [
    { name: 'Dashboard', href: '/', icon: ViewGridIcon },
    { name: 'Bookings', href: '/bookings', icon: CollectionIcon },
    { name: 'Calendar', href: '/calendar', icon: CalendarIcon },
    { name: 'Users', href: '/users', icon: UserIcon },
];

export const userNavigation = [{ name: 'Settings', href: '/profile' }];
