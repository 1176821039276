const Footer = () => {
    return (
        <></>
        // <footer className="bg-white">
        //     <div className="mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        //         <div className="flex justify-center space-x-6 md:order-2">
        //             {navigation.map((item) => (
        //                 <a
        //                     key={item.name}
        //                     href={item.href}
        //                     className="text-neutral-400 hover:text-neutral-500"
        //                 >
        //                     <span className="sr-only">{item.name}</span>
        //                     <item.icon className="h-6 w-6" aria-hidden="true" />
        //                 </a>
        //             ))}
        //         </div>
        //         <div className="mt-8 md:mt-0 md:order-1">
        //             <p className="text-center text-base text-neutral-400">
        //                 &copy; Event Staff Scotland, Inc. All rights reserved.
        //             </p>
        //         </div>
        //     </div>
        // </footer>
    );
};

export default Footer;
