import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '../../../components/Buttons';
import { getAllUsers } from '../../../services/firebase-user-service';
import { LoadingSection } from '../../misc/Loading';
import { NotificationWrapper } from '../../../components/Notifications';

export const UserList = () => {
    const [loading, setLoading] = useState(true);
    const [directory, setDirectory] = useState({});

    useEffect(() => {
        const users = async () => {
            let users = await getAllUsers();
            let userList = users.reduce((r, e) => {
                let group = e.last_name[0];
                if (!r[group]) r[group] = [e];
                else r[group].push(e);
                return r;
            }, {});
            setDirectory(userList);
            setLoading(false);
        };
        users();
    }, []);

    if (loading) {
        return <LoadingSection />;
    }
    return (
        <>
            <NotificationWrapper />
            <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-between text-center sm:text-left mb-3">
                <div className="prose">
                    <h1>Users</h1>
                </div>
                <div className="sm:flex items-center mb-3">
                    <div className="mx-auto my-4 sm:mr-3 order-2 sm:order-1 text-center">
                        <PrimaryButton
                            to={`/users/new`}
                            title={`Add New User`}
                        />
                    </div>
                </div>
            </div>
            <div className={`bg-white shadow rounded-lg`}>
                <div className="">
                    <nav
                        className="h-full overflow-y-auto"
                        aria-label="Directory"
                    >
                        {Object.keys(directory).map((letter, index) => (
                            <div key={letter} className="relative">
                                <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-50 px-6 py-1 text-sm font-medium text-gray-500">
                                    <h3>{letter}</h3>
                                </div>
                                <ul className="relative z-0 divide-y divide-gray-200">
                                    {directory[letter].map((user) => (
                                        <li key={user.uid} className="bg-white">
                                            <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500">
                                                <div className="flex-shrink-0">
                                                    {user.imageUrl ? (
                                                        <img
                                                            className="inline-block h-8 w-8 rounded-full"
                                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                            alt=""
                                                        />
                                                    ) : (
                                                        <span className="inline-block h-8 w-8 rounded-full overflow-hidden bg-neutral-100">
                                                            <svg
                                                                className="h-full w-full text-neutral-300"
                                                                fill="currentColor"
                                                                viewBox="0 0 24 24"
                                                            >
                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                            </svg>
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <Link
                                                        to={`/users/${user.uid}`}
                                                        className="focus:outline-none flex justify-between items-center group"
                                                    >
                                                        <div>
                                                            <span
                                                                className="absolute inset-0"
                                                                aria-hidden="true"
                                                            />
                                                            <p className="text-sm font-medium text-gray-900">
                                                                {
                                                                    user.first_name
                                                                }{' '}
                                                                {user.last_name}
                                                            </p>
                                                            <p className="text-sm text-gray-500 truncate">
                                                                {user.email}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p className="text-sm text-neutral-300 opacity-0 group-hover:opacity-100">
                                                                Edit
                                                            </p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </nav>
                </div>
            </div>
        </>
    );
};
