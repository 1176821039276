import React from 'react';
import { SpaceWrapper } from '../../components/SpaceWrapper';
import Welcome from './Welcome';
import UpcomingBookings from './UpcomingBookings';

const Dashboard = () => {
    return (
        <SpaceWrapper>
            <Welcome />
            <UpcomingBookings />
        </SpaceWrapper>
    );
};

export default Dashboard;
