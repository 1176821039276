import { useParams, useSearchParams } from 'react-router-dom';
import { SpaceWrapper } from '../../components/SpaceWrapper';
import BookingDetails from './detail';
import { BookingList } from './list';
import { AddBooking } from './create';
import { EditBooking } from './edit';

export const BookingListView = () => {
    return (
        <SpaceWrapper>
            <div className="max-w-4xl mx-auto relative">
                <BookingList />
            </div>
        </SpaceWrapper>
    );
};

export const BookingDetailView = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();

    return (
        <SpaceWrapper>
            <div className="max-w-4xl mx-auto relative">
                {searchParams.get('edit') ? (
                    <EditBooking />
                ) : (
                    <BookingDetails id={params.id} />
                )}
            </div>
        </SpaceWrapper>
    );
};

export const BookingCreateView = () => {
    return (
        <SpaceWrapper title="Request New Booking">
            <div className="max-w-4xl mx-auto mt-6">
                <AddBooking />
            </div>
        </SpaceWrapper>
    );
};
