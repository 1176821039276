import React, { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getSingleBooking } from '../../../services/firebase-booking-service';
import AuthContext from '../../../context/AuthContext';

import { BookingForm } from './BookingForm';
import { Loading } from '../../misc/Loading';

export const AddBooking = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { user, info, loading } = useContext(AuthContext);
    // eslint-disable-next-line no-unused-vars
    const [searchParams, setSearchParams] = useSearchParams();
    const [initial, setInitial] = useState({});
    useEffect(() => {
        if (searchParams.get('from')) {
            let booking = async () => {
                let data = await getSingleBooking(searchParams.get('from'));
                setInitial({
                    ...data,
                    booking_notes: '',
                    event_date: data.event_date.toDate(),
                });
                setIsLoading(false);
            };
            booking();
        } else {
            setInitial({
                contact_name: user.displayName,
                contact_email: user.email,
                contact_telephone: info.telephone,
                company_name: info.company_name,
                event_type: '',
            });
            setIsLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {loading || isLoading ? (
                <Loading />
            ) : (
                <BookingForm edit={false} initial={initial} />
            )}
        </>
    );
};
