import { auth, db } from '../config/firebase';
import {
    query,
    collection,
    where,
    getDocs,
    doc,
    addDoc,
    Timestamp,
    updateDoc,
    getDoc,
} from 'firebase/firestore';

// GET ALL EVENTS (USER)
const getAllEventsByUser = async (uid) => {
    const q = query(collection(db, 'bookings'), where('uid', '==', uid));
    const docs = await getDocs(q);
    return docs.docs.map((doc) => {
        return {
            ...doc.data(),
            id: doc.id,
        };
    });
};

// GET EVENT BY DATE
const getBookingByDate = async (date, admin = false) => {
    let events = [];

    try {
        let uid = auth.currentUser.uid;
        let start = date.setHours(0, 0, 0);
        let end = date.setHours(23, 59, 59);

        const q = admin
            ? query(
                  collection(db, 'bookings'),
                  where('event_date', '>=', new Date(start)),
                  where('event_date', '<=', new Date(end))
              )
            : query(
                  collection(db, 'bookings'),
                  where('uid', '==', uid),
                  where('event_date', '>=', new Date(start)),
                  where('event_date', '<=', new Date(end))
              );
        const docs = await getDocs(q);
        docs.forEach((doc) => {
            events.push({ id: doc.id, ...doc.data() });
        });
    } catch (error) {
        console.error(error.message);
    }

    return events;
};

const getSingleBooking = async (id) => {
    const docRef = doc(db, 'bookings', id);
    const docSnap = await getDoc(docRef);
    return docSnap.data();
};

// CREATE EVENT
const createBooking = async (data) => {
    let bookingData = {
        ...data,
        event_date: Timestamp.fromDate(data.event_date),
        active: true,
        status: 'Awaiting Approval',
        created: Timestamp.now(),
        updated: Timestamp.now(),
        uid: data.uid ? data.uid : auth.currentUser.uid,
    };
    await addDoc(collection(db, 'bookings'), bookingData);
};

// UPDATE EVENT
const updateBooking = async (data, id, isAdmin) => {
    let bookingData = {
        ...data,
        event_date: Timestamp.fromDate(data.event_date),
        status: isAdmin ? data.status : 'Awaiting Approval',
        active: true,
        created: new Timestamp(data.created.seconds, data.created.nanoseconds),
        updated: Timestamp.now(),
    };
    await updateDoc(doc(db, 'bookings', id), bookingData);
};

const updateBookingNotes = async (data, id) => {
    let bookingData = {
        ...data,
        updated: Timestamp.now(),
    };
    await updateDoc(doc(db, 'bookings', id), bookingData);
};

// UPDATE STATUS
const updateBookingStatus = async (status, id, isAdmin) => {
    let active = true;
    if (status === 'Cancelled' || status === 'Completed') {
        active = false;
    }
    await updateDoc(doc(db, 'bookings', id), {
        active: active,
        status: status,
        updated: Timestamp.now(),
    });
};

// CANCEL EVENT
const cancelBooking = async (id, isAdmin) => {
    let bookingData;

    if (isAdmin) {
        bookingData = {
            active: false,
            status: 'Cancelled',
            updated: Timestamp.now(),
        };
    } else {
        bookingData = {
            status: 'Pending Cancellation',
            updated: Timestamp.now(),
        };
    }
    await updateDoc(doc(db, 'bookings', id), bookingData);
};

// EXPORTS
export {
    getBookingByDate,
    getSingleBooking,
    getAllEventsByUser,
    createBooking,
    updateBooking,
    updateBookingNotes,
    updateBookingStatus,
    cancelBooking,
};
