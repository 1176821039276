import { BookingListWrapper } from '../bookings/list/BookingList';
import Section from '../../components/Section';

const UpcomingBookings = () => {
    return (
        <>
            <div className="text-lg font-bold p-5 mt-6">Upcoming Bookings</div>
            <Section>
                <BookingListWrapper status={'Upcoming'} limit={3} />
            </Section>
        </>
    );
};

export default UpcomingBookings;
