import { useEffect, useState } from 'react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { Combobox } from '@headlessui/react';
import { getAllUsers } from '../services/firebase-user-service';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

export const UserSelect = ({ handleChange, user }) => {
    const [query, setQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();

    const filteredUsers =
        query === ''
            ? users
            : users.filter((person) => {
                  return person.last_name
                      .toLowerCase()
                      .includes(query.toLowerCase());
              });

    useEffect(() => {
        const getUserList = async () => {
            let userList = await getAllUsers();
            setUsers(userList);
        };
        getUserList();
        return () => {};
    }, []);

    useEffect(() => {
        if (user) {
            let find = users.find((obj) => {
                return obj.id === user;
            });
            setSelectedUser(find);
        }
    }, [user, users]);

    useEffect(() => {
        if (selectedUser) {
            handleChange(selectedUser.uid);
        }
        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedUser]);

    return (
        <Combobox as="div" value={selectedUser} onChange={setSelectedUser}>
            <div className="relative mt-1">
                <Combobox.Input
                    className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                    onChange={(event) => setQuery(event.target.value)}
                    displayValue={(person) =>
                        `${person.first_name} ${person.last_name}`
                    }
                />
                <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                    <SelectorIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                    />
                </Combobox.Button>

                {filteredUsers.length > 0 && (
                    <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {filteredUsers.map((person) => (
                            <Combobox.Option
                                key={person.id}
                                value={person}
                                className={({ active }) =>
                                    classNames(
                                        'relative cursor-default select-none py-2 pl-8 pr-4',
                                        active
                                            ? 'bg-brand text-gray-900'
                                            : 'text-gray-900'
                                    )
                                }
                            >
                                {({ active, selected }) => (
                                    <>
                                        <span
                                            className={classNames(
                                                'block truncate',
                                                selected && 'font-semibold'
                                            )}
                                        >
                                            {`${person.first_name} ${person.last_name}`}
                                        </span>

                                        {selected && (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-1.5">
                                                <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        )}
                                    </>
                                )}
                            </Combobox.Option>
                        ))}
                    </Combobox.Options>
                )}
            </div>
        </Combobox>
    );
};
