import React, { useContext } from 'react';
import {
    Outlet,
    Routes,
    Route,
    useMatch,
    useResolvedPath,
    Link,
} from 'react-router-dom';

import { adminNavigation, navigation } from '../../config/LocalData';
import { logout } from '../../services/firebase-auth-service';

import AuthContext from '../../context/AuthContext';
import BookingContext from '../../context/BookingContext';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import Dashboard from '../dashboard';
import Calendar from '../calendar';
import Profile from '../profile';
import { UserCreateView, UserDetailView, UserListView } from '../users';
import {
    BookingListView,
    BookingDetailView,
    BookingCreateView,
} from '../bookings';
import { BlankSpace } from '../misc/BlankSpace';

const DashboardLink = ({ children, item, count, ...props }) => {
    let resolved = useResolvedPath(item.href);
    let match = useMatch({ path: resolved.pathname, end: true });

    return (
        <Link
            to={item.href}
            className={`flex items-center text-white p-4 sm:px-6 border-b border-b-neutral-800 group`}
            aria-current={match ? 'page' : undefined}
        >
            <item.icon
                className={`
                    w-8 h-8 mr-4 rounded-full p-1 group-hover:bg-brand group-hover:text-gray-900
                    ${match ? 'bg-brand text-gray-900' : 'text-white'}
                `}
            />
            <div>{item.name}</div>{' '}
            {item.name === 'Bookings' ? (
                <span className="inline-flex items-center px-2.5 py-0.5 ml-2 rounded-full text-xs font-medium bg-brand text-gray-900">
                    {count}
                </span>
            ) : null}
        </Link>
    );
};

const CoreLayout = ({ nav, children }) => {
    const { user } = useContext(AuthContext);
    const { bookingCount } = useContext(BookingContext);
    return (
        <div className="flex h-screen justify-end">
            <div className="hidden lg:block fixed w-1/4 max-w-xs bg-neutral-900 z-50 h-screen left-0">
                <div className="flex flex-col space-between h-full justify-between">
                    <div className="flex-1">
                        <div className="border-b border-b-neutral-800">
                            <div className="flex items-center justify-center p-6 py-12">
                                <img
                                    className="block h-8 w-auto"
                                    src="https://eventstaffscotland.co.uk/wp-content/themes/website-eventstaffscotland-2022/_assets/brand/header-brand-ess.svg"
                                    alt="Event Staff Scotland"
                                />
                            </div>
                        </div>
                        {nav.map((item, index) => (
                            <DashboardLink
                                item={item}
                                key={`key-${item.name}`}
                                count={bookingCount}
                            />
                        ))}
                    </div>
                    <div>
                        <div className="border-t border-t-neutral-800">
                            <Link
                                className="flex items-center p-4 sm:px-6 hover:bg-brand group"
                                to={`/profile`}
                            >
                                {user.imageUrl ? (
                                    <img
                                        className="block h-10 w-10 rounded-full"
                                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                        alt=""
                                    />
                                ) : (
                                    <span className="block h-10 w-10 rounded-full overflow-hidden bg-neutral-100">
                                        <svg
                                            className="h-full w-full text-neutral-300"
                                            fill="currentColor"
                                            viewBox="0 0 24 24"
                                        >
                                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                        </svg>
                                    </span>
                                )}

                                <div className="ml-3 flex-1 truncate whitespace-nowrap">
                                    <div className="text-base font-medium text-neutral-300 group-hover:text-gray-900">
                                        {user.displayName}
                                    </div>
                                    <div className="text-sm font-medium text-neutral-500 group-hover:text-gray-900">
                                        {user.email}
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="border-t border-t-neutral-800">
                            <button
                                onClick={logout}
                                className="block text-center p-4 sm:px-6 w-full text-neutral-500 hover:text-brand"
                            >
                                Sign out
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hidden lg:block w-1/4 max-w-xs"></div>
            <div className="flex-1">
                <Header navigation={nav} />
                {children}
            </div>
        </div>
    );
};

export const ClientArea = () => {
    return (
        <div className="min-h-full">
            <CoreLayout nav={navigation}>
                <Outlet />
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route path="/bookings" element={<BookingListView />} />
                    <Route
                        path="/bookings/:id"
                        element={<BookingDetailView />}
                    />
                    <Route
                        path="/bookings/create"
                        element={<BookingCreateView />}
                    />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route path="*" element={<BlankSpace />} />
                </Routes>
            </CoreLayout>
            <Footer />
        </div>
    );
};

export const AdminArea = () => {
    return (
        <div className="min-h-screen">
            <CoreLayout nav={adminNavigation}>
                <Outlet />
                <Routes>
                    <Route exact path="/" element={<Dashboard />} />
                    <Route path="/bookings" element={<BookingListView />} />
                    <Route
                        path="/bookings/:id"
                        element={<BookingDetailView />}
                    />
                    <Route
                        path="/bookings/create"
                        element={<BookingCreateView />}
                    />
                    <Route path="/calendar" element={<Calendar />} />
                    <Route path="/profile" element={<Profile />} />
                    <Route exact path="/users" element={<UserListView />} />
                    <Route path="/users/:id" element={<UserDetailView />} />
                    <Route path="/users/new" element={<UserCreateView />} />
                    <Route path="*" element={<BlankSpace />} />
                </Routes>
            </CoreLayout>
            <Footer />
        </div>
    );
};
