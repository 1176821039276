import React, { useEffect, useState, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Transition } from '@headlessui/react';
import { getCalendarData } from '../../config/Data';

import { LoadingSection } from '../misc/Loading';
import { SpaceWrapper } from '../../components/SpaceWrapper';
import MonthView from './MonthView';

import {
    ChevronLeftIcon,
    ChevronRightIcon,
    DotsHorizontalIcon,
} from '@heroicons/react/solid';
import AuthContext from '../../context/AuthContext';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const Calendar = () => {
    const { admin } = useContext(AuthContext);
    const [activeDate, setActiveDate] = useState(new Date());
    const [days, setDays] = useState(null);
    const [label, setLabel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const initData = async (input) => {
        let data = await getCalendarData(input, admin);
        setDays(data.days);
        setLabel(data.label);
    };

    useEffect(() => {
        setIsLoading(true);
        initData(activeDate).then((response) => {
            setIsLoading(false);
        });
    }, [activeDate]);

    const changeDate = (date) => {
        setActiveDate(date);
    };

    const previousMonth = () => {
        let newMonth =
            activeDate.getMonth() !== 0
                ? new Date(
                      activeDate.getFullYear(),
                      activeDate.getMonth() - 1,
                      1
                  )
                : new Date(activeDate.getFullYear() - 1, 11, 1);
        return changeDate(newMonth);
    };

    const nextMonth = () => {
        let newMonth =
            activeDate.getMonth() !== 11
                ? new Date(
                      activeDate.getFullYear(),
                      activeDate.getMonth() + 1,
                      1
                  )
                : new Date(activeDate.getFullYear() + 1, 0, 1);
        return changeDate(newMonth);
    };

    return (
        <SpaceWrapper title="Booking Calendar">
            {isLoading ? (
                <div className="min-h-[500px] mt-8 flex items-center justify-center">
                    <LoadingSection scheme="light" />
                </div>
            ) : (
                <div className="lg:flex lg:h-full lg:flex-col mt-8">
                    <header className="relative z-20 flex items-center justify-between border-b border-neutral-200 py-4 px-6 lg:flex-none">
                        <h1 className="text-lg font-semibold text-neutral-900">
                            <time dateTime="2022-01">{label}</time>
                        </h1>
                        <div className="flex items-center">
                            <div className="flex items-center rounded-md shadow-sm md:items-stretch">
                                <button
                                    type="button"
                                    onClick={previousMonth}
                                    className="flex items-center justify-center rounded-l-md border border-r-0 border-neutral-300 bg-white py-2 pl-3 pr-4 text-neutral-400 hover:text-neutral-500 focus:relative md:w-9 md:px-2 md:hover:bg-neutral-50"
                                >
                                    <span className="sr-only">
                                        Previous month
                                    </span>
                                    <ChevronLeftIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => changeDate(new Date())}
                                    className="hidden border-t border-b border-neutral-300 bg-white px-3.5 text-sm font-medium text-neutral-700 hover:bg-neutral-50 hover:text-neutral-900 focus:relative md:block"
                                >
                                    Today
                                </button>
                                <span className="relative -mx-px h-5 w-px bg-neutral-300 md:hidden" />
                                <button
                                    type="button"
                                    onClick={nextMonth}
                                    className="flex items-center justify-center rounded-r-md border border-l-0 border-neutral-300 bg-white py-2 pl-4 pr-3 text-neutral-400 hover:text-neutral-500 focus:relative md:w-9 md:px-2 md:hover:bg-neutral-50"
                                >
                                    <span className="sr-only">Next month</span>
                                    <ChevronRightIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <Menu as="div" className="relative ml-6 md:hidden">
                                <Menu.Button className="-mx-2 flex items-center rounded-full border border-transparent p-2 text-neutral-400 hover:text-neutral-500">
                                    <span className="sr-only">Open menu</span>
                                    <DotsHorizontalIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </Menu.Button>

                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="focus:outline-none absolute right-0 mt-3 w-36 origin-top-right divide-y divide-neutral-100 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <Link
                                                        to="/bookings"
                                                        className={classNames(
                                                            active
                                                                ? 'bg-neutral-100 text-neutral-900'
                                                                : 'text-neutral-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Create event
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        </div>
                                        <div className="py-1">
                                            <Menu.Item>
                                                {({ active }) => (
                                                    <button
                                                        onClick={() =>
                                                            changeDate(
                                                                new Date()
                                                            )
                                                        }
                                                        className={classNames(
                                                            active
                                                                ? 'bg-neutral-100 text-neutral-900'
                                                                : 'text-neutral-700',
                                                            'block px-4 py-2 text-sm'
                                                        )}
                                                    >
                                                        Go to today
                                                    </button>
                                                )}
                                            </Menu.Item>
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>
                        </div>
                    </header>
                    <MonthView data={days} month={label} />
                </div>
            )}
        </SpaceWrapper>
    );
};

export default Calendar;
