import { useParams, useSearchParams } from 'react-router-dom';
import { SpaceWrapper } from '../../components/SpaceWrapper';
import { UserDetails } from './details';
import { UserEdit } from './edit';
import { UserList } from './list';
import { AddUser } from './new';
import { generateUserPassword } from '../../services/firebase-user-service';

export const UserListView = () => {
    return (
        <SpaceWrapper>
            <div className="max-w-4xl mx-auto relative">
                <UserList />
            </div>
        </SpaceWrapper>
    );
};

export const UserDetailView = () => {
    const [searchParams] = useSearchParams();
    const params = useParams();

    return (
        <SpaceWrapper>
            <div className="max-w-4xl mx-auto relative">
                {searchParams.get('edit') ? (
                    <UserEdit />
                ) : (
                    <UserDetails id={params.id} />
                )}
            </div>
        </SpaceWrapper>
    );
};

export const UserCreateView = () => {
    return (
        <SpaceWrapper title="Create a new user">
            <AddUser initial={{ password: generateUserPassword(8) }} />
        </SpaceWrapper>
    );
};
