import { useContext } from 'react';
import { Link } from 'react-router-dom';
import AuthContext from '../../context/AuthContext';
import BookingContext from '../../context/BookingContext';
import { ShieldCheckIcon } from '@heroicons/react/outline';

export default function Welcome() {
    const { user, admin } = useContext(AuthContext);
    const { bookingStats } = useContext(BookingContext);

    return (
        <>
            <section aria-labelledby="profile-Dashboard-title">
                <div className="rounded-lg bg-white overflow-hidden shadow">
                    <h2 className="sr-only" id="profile-Dashboard-title">
                        Profile Dashboard
                    </h2>
                    <div className="bg-white p-6">
                        <div className="sm:flex sm:items-center sm:justify-between">
                            <div className="sm:flex sm:space-x-5 items-center">
                                <div className="flex-shrink-0 text-center">
                                    {user.imageUrl ? (
                                        <img
                                            className="inline-block h-20 w-20 rounded-full"
                                            src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                            alt=""
                                        />
                                    ) : (
                                        <span className="inline-block h-20 w-20 rounded-full overflow-hidden bg-neutral-100">
                                            <svg
                                                className="h-full w-full text-neutral-300"
                                                fill="currentColor"
                                                viewBox="0 0 24 24"
                                            >
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>
                                    )}
                                </div>
                                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                                    <p className="text-sm font-medium text-neutral-600">
                                        Welcome back,
                                    </p>
                                    <p className="text-xl font-bold text-neutral-900 sm:text-2xl flex items-center justify-center">
                                        {user.displayName}
                                        {admin && (
                                            <ShieldCheckIcon className="text-highlight-500 w-5 h-5 ml-3" />
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="mt-5 flex justify-center sm:mt-0">
                                <Link
                                    to={`/profile`}
                                    className="flex justify-center items-center px-4 py-2 border border-neutral-300 shadow-sm text-sm font-medium rounded-md text-neutral-700 bg-white hover:bg-neutral-50"
                                >
                                    View profile
                                </Link>
                            </div>
                        </div>
                    </div>
                    <dl className="flex flex-col md:flex-row border-t border-t-neutral-300 divide-y divide-y-neutral-300 md:divide-y-0 md:divide-x md:divide-x-neutral-300">
                        {bookingStats.map((item) => (
                            <div
                                key={item.name}
                                className="px-4 py-5 flex-1 bg-white overflow-hidden sm:p-6 text-center"
                            >
                                <dt className="text-sm font-medium text-gray-500 truncate">
                                    {item.name}
                                </dt>
                                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                                    {item.stat}
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </section>
        </>
    );
}
